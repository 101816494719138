.sidebar {
    padding-right: 0 !important;
}

.sidebar-2022 {
    position: fixed;
    left: 0;
    top: 0;
    display: none;
    width: 100%;
    height: 100%;
    z-index: 17000000;
    background: #fff;

    &._show {
        display: block;
    }

    &__scroll {
        width: 100%;
        height: 100%;
        padding-bottom: 62px;
        overflow: auto;
    }

    &__title {
        position: relative;
        padding: 20px 30px;
        font-family: 'Raleway', sans-serif;
        font-size: 20px;
        font-weight: 900;
        letter-spacing: 2px;
        border-bottom: #9AA7B2 1px solid;
        color: #9AA7B2;
    }

    &__close {
        position: absolute;
        right: 15px;
        top: 15px;
        z-index: 20;
        width: 40px;
        height: 40px;
        font-size: 0;
        background: url('../images/icon-close2.svg') center center no-repeat;
        background-size: contain;
        border: none;
        opacity: 1;

        &:hover {
            opacity: .75;
        }
    }

    &__filter {
        $self: &;

        border-bottom: #9AA7B2 1px solid;

        &__title {
            &,
            &h4 {
                position: relative;
                padding: 20px 60px 20px 30px !important;
                margin: 0;
                font-family: 'Raleway', sans-serif;
                font-weight: 900;
                border-bottom: none !important;
                color: #000 !important;

                &::after {
                    content: '';
                    position: absolute;
                    right: 30px;
                    top: 50%;
                    width: 30px;
                    height: 30px;
                    background: url('../images/icon-arrow2.svg') center center no-repeat;
                    background-size: contain;
                    transform: translateY(-50%);
                }
            }
        }

        &__options {
            display: none;
            padding: 20px 30px;

            &__item {
                display: flex;
                align-items: center;
                margin-top: 15px;

                &:first-child {
                    margin-top: 0;
                }

                input[type="checkbox"] {
                    flex: 0 0 20px;
                    max-width: 20px;
                    appearance: none;
                    -webkit-appearance: none;
                    width: 20px;
                    height: 20px;
                    margin-right: 15px;
                    background-color: #fff;
                    border-color: #9AA7B2;
                    border-style: solid;
                    border-top-width: 1px;
                    border-right-width: 2px;
                    border-bottom-width: 2px;
                    border-left-width: 1px;
                    border-radius: 4px;

                    &:checked {
                        background-color: $brand-primary;
                    }
                }

                select.form-control {
                    border-color: $brand-secondary;
                }

                &__trigger {
                    flex-basis: 0;
                    flex-grow: 1;
                    max-width: 100%;
                    color: #000;

                    &:hover {
                        text-decoration: none;
                        color: #000;
                    }
                }

                &__dates {
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: -15px;

                    .date-label,
                    .date-input {
                        flex: 0 0 50%;
                        max-width: 50%;
                        margin-bottom: 15px;
                    }

                    .date-input {
                        input {
                            width: 100%;
                        }
                    }
                }

                &--subcategory {
                    padding-left: 30px;
                }
            }
        }

        &._show {
            #{ $self }__title {
                &,
                &h4 {
                    &::after {
                        transform: translateY(-50%) scaleY(-1);
                    }
                }
            }

            #{ $self }__options {
                display: block;
            }
        }
    }

    &__infobox {
        display: none;
    }

    &__footer {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 10;
        width: 100%;
        padding: 10px;
        overflow: hidden;
        background: #000;

        .flex-row {
            margin-right: 0;
            margin-left: 0;
        }

        &__action {
            display: block;
            width: 100%;
            height: 100%;
            font-family: 'Raleway', sans-serif;
            font-size: 16px;
            font-weight: 900;
            text-align: center;
            text-transform: uppercase;
            border: none;
            color: #fff;

            &.clear {
                background-color: #fff;
                color: #000;
            }

            &.apply {
                background-color: $brand-primary;
            }

            span {
                display: block;
                font-size: 12px;
            }
        }
    }

    &__controls {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        overflow: hidden;

        &__trigger-wrapper {
            flex: 0 0 auto;
            width: auto;
            max-width: 100%;
            margin-right: 30px;

            .trigger {
                position: relative;
                padding: 5px 40px 5px 10px;
                font-family: 'Raleway', sans-serif;
                font-size: 16px;
                font-weight: 700;
                text-transform: uppercase;
                background: none;
                border: $brand-secondary 1px solid;
                border-radius: 3px;
                color: $brand-secondary;

                &::after {
                    content: '';
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    width: 20px;
                    height: 20px;
                    background: url('../images/icon-filters.svg') center center no-repeat;
                    background-size: contain;
                    transform: translateY(-50%);
                }
            }
        }

        &__categories {
            display: flex;
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
            margin-right: -7.5px;
            margin-left: -7.5px;
            overflow: auto;

            &__item {
                $self: &;

                flex: 0 0 45vw;
                max-width: 45vw;
                padding-right: 7.5px;
                padding-left: 7.5px;
                overflow: hidden;

                .flex-row {
                    align-items: center;
                    margin-right: -5px;
                    margin-left: -5px;

                    .flex-col {
                        padding-right: 5px;
                        padding-left: 5px;

                        &:first-child {
                            flex: 0 0 auto;
                            width: auto;
                            max-width: 100px;
                        }
                    }
                }

                &__image {
                    padding: 3px;
                    border-top-left-radius: 100%;
                    border-bottom-left-radius: 100%;
                    border-bottom-right-radius: 100%;
                    background: $brand-secondary;

                    img {
                        width: 50px;
                        border-radius: 100%;
                    }
                }

                &__title {
                    font-family: 'Raleway', sans-serif;
                    font-size: 16px;
                    font-weight: 900;
                }

                &.active {
                    #{ $self }__image {
                        background: #000;
                    }
                }
            }
        }
    }

    &__cta {
        margin-bottom: 60px;
        text-align: center;

        .btn {
            text-transform: uppercase;

            &.btn-primary {
                background-color: #000;
                border-color: #000;
            }
        }
    }

    @media (min-width: $screen-md-min) {
        position: relative;
        left: auto;
        top: auto;
        display: block;
        width: auto;
        height: auto;
        z-index: initial;

        &__scroll {
            overflow: initial;
        }

        &__title {
            display: none;
        }

        &__filter {
            margin-bottom: 40px;
            border-bottom: none;

            &__title {
                padding: 0 0 20px 0 !important;
                margin-bottom: 20px !important;
                border-bottom: #9AA7B2 1px solid !important;

                &::after {
                    display: none;
                }
            }

            &__options {
                display: block;
                padding: 0;
            }
        }

        &__infobox {
            display: block;
            position: relative;
            padding: 95px 25px 30px;
            margin-top: 120px;
            background: #fff;
            border: 2px solid #E6EBEE;
            box-shadow: 4px 4px 0 0 $brand-secondary;

            &__heading {
                position: relative;
                margin-bottom: 30px;

                &__title {
                    position: absolute;
                    left: -45px;
                    bottom: 45px;
                    z-index: 20;
                    width: 75%;
                    font-family: Brittany,serif;
                    font-size: 42px;
                    font-weight: 400;
                    text-align: center;
                    transform: rotate(-5deg);
                    white-space: nowrap;
                }

                &__image {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    overflow: hidden;
                    border: #fff 2px solid;
                    border-radius: 100%;
                }
            }

            &__content {
                &__plus {
                    @include list-unstyled();

                    margin-bottom: 30px;
                    font-family: 'Raleway', sans-serif;
                    font-size: 18px;
                    font-weight: 900;

                    li {
                        position: relative;
                        padding-left: 30px;
                        margin-top: 15px;

                        &::before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 50%;
                            width: 22px;
                            height: 16px;
                            background: url('../images/icon-check2.png') center center no-repeat;
                            background-size: contain;
                            transform: translateY(-50%);
                        }

                        &:first-child {
                            margin-top: 0;
                        }
                    }
                }

                &__cta {
                    text-align: center;

                    strong {
                        font-family: 'Raleway', sans-serif;
                        font-size: 20px;
                        font-weight: 900;
                        text-transform: uppercase;
                    }

                    a {
                        font-size: 20px;
                        color: #000;
                    }
                }
            }
        }

        &__controls {
            display: none;
        }

        &__footer {
            display: none;
        }
    }
}
