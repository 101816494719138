.modal {
    &-backdrop {
        background: #fff;

        &.in {
            opacity: .5;
        }
    }

    &--nywelcome {
        text-align: right;

        .modal {
            &-dialog {
                width: 100%;
                height: 100%;
                margin: 0;
                transition: right 0.3s ease-in-out;
            }

            &-content {
                height: 100%;
                overflow: auto;
                text-align: left;
                box-shadow: none;

                .modal-header {
                    padding: 0;
                    text-align: left;

                    .close {
                        position: absolute;
                        right: 15px;
                        top: 15px;
                        z-index: 20;
                        width: 40px;
                        height: 40px;
                        font-size: 0;
                        background: url('../images/icon-close2.svg') center center no-repeat;
                        background-size: contain;
                        border: none;
                        opacity: 1;

                        &:hover {
                            opacity: .75;
                        }
                    }

                    .modal-title {
                        padding: 20px 30px;
                        margin-bottom: 20px;
                        font-family: 'Raleway', sans-serif;
                        font-size: 20px;
                        font-weight: 900;
                        letter-spacing: 2px;
                        text-align: left;
                        border-bottom: #9AA7B2 1px solid;
                        color: #9AA7B2;
                    }
                }
            }
        }

        &.in {
            .modal {
                &-dialog {
                    right: 0 !important;
                }
            }
        }
    }

    @media (min-width: $screen-sm-min) {
        &--nywelcome {
            .modal {
                &-dialog {
                    max-width: 500px;
                    border-left: #000 1px solid;
                }
            }
        }
    }

    @media (min-width: $screen-md-min) {
        &--nywelcome {
            .modal {
                &-dialog {
                    right: -700px;
                    max-width: 700px;
                }
            }
        }
    }

    @media (min-width: 1600px) {
        &--nywelcome {
            .modal {
                &-content {
                    .modal-header {
                        .modal-title {
                            padding-top: 20px;
                            padding-bottom: 20px;
                            margin-bottom: 40px;
                            font-size: 24px;
                        }
                    }
                }
            }
        }
    }
}

#modalforbanner {
    background: rgba(0,0,0,.75) !important;
}

body.modal-open {
    .zopim {
        display: none !important;
    }
}
