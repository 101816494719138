.explore-card {
    position: relative;
    border: #000 1px solid;

    &--has-footer {
        padding-bottom: 30px;
    }

    &__link {
        display: block;
        color: #000;

        &:hover {
            text-decoration: none;
            color: #000;
        }
    }

    &__image {
        position: relative;
        overflow: hidden;

        .img-responsive {
            width: 100%;
        }
    }

    &__content {
        padding: 15px;
        text-align: center;
    }

    &__title {
        position: relative;
        z-index: 10;
        display: block;
        padding: 15px;
        margin-top: -15px;
        font-family: 'Raleway', sans-serif;
        font-size: 20px;
        font-weight: 900;
        background: #000;
        color: #fff;
        transform: translateY(-50%);
    }

    &__description {
        display: block;
        margin-top: -15px;
        margin-bottom: 20px;
        font-family: 'Raleway', sans-serif;
        font-size: 18px;
        font-weight: 100;
        text-transform: uppercase;
    }

    &__text {
        display: block;
        margin-bottom: 20px;
        font-size: 16px;
    }

    &__dates {
        display: block;
        margin-bottom: 20px;
        font-size: 16px;
    }

    &__card-footer {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-top: #000 1px solid;

        .icon {
            position: absolute;
            left: 15px;
            top: 50%;
            width: 22px;
            transform: translateY(-50%);
        }

        .text {
            padding: 10px 15px 10px 50px;
            font-size: 14px;
            font-weight: 600;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}

div.kkexploresdbar {
    .kkexploresdbarlink,
    .kkexploresdbartitle {
        position: relative;
        padding: 0 0 20px;
        margin: 0 0 20px 0;
        font-family: Raleway, sans-serif;
        font-size: 18px;
        font-weight: 700;
        border-bottom: 1px solid #9aa7b2;
        color: #000;

        &:hover {
            opacity: 1;
        }
    }

    ul {
        margin-bottom: 45px;

        li {
            a {
                color: #000;

                &:hover {
                    background: none;
                }
            }
        }
    }

    .kkexploresdbartour {
        background-color: #000;
    }

    &sx {
        .tour-card {
            margin-bottom: 30px;
        }
    }

    @media (min-width: $screen-sm-min) {
        padding-left: 30px;

        &sx {
            padding-left: 0;
            padding-right: 30px;
            border-right: none;

            .tour-card {
                margin-bottom: 0;
            }
        }
    }
}
