body{
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	font-weight: 400;
	background: #f2f2f2;
	color: #000;
	-webkit-font-smoothing: antialiased;
}

a{
	color: $brand-secondary;

	&:hover, &:focus{
		color: lighten($brand-secondary, 10%);
		outline: none;
	}
}

h1, h2, h3, h4, h5, h6{
	font-weight: 600;
}

::selection {
	background: $brand-primary;
	color: #fff;
	@include opacity(1);
}
::-moz-selection {
	background: $brand-primary;
	color: #fff;
	@include opacity(1);
}

.wrapper{
	position: relative;
	width: 100%;
	max-width: 1920px;
	margin: 0 auto;
	overflow: hidden;
	background: #fff;
}

textarea{
	resize: none;
}

.form-control{
	box-shadow: none;

	&:focus{
		box-shadow: none;
	}
}

.custom-select{
	position: relative;
	background: $brand-secondary;

	&:before{
		content: '';
		position: absolute;
		top: 0; right: 0;
		display: block;
		width: 44px; height: 44px;
		background: url('../images/arrow-select.png') center center no-repeat;
		background-size: 18px 10px;
	}
	select{
		position: relative;
		z-index: 20;
		background: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		text-indent: 1px;
		text-overflow: '';

		&::-ms-expand {
			display: none;
		}
	}
	&.has-error{
		.form-control{
			border-color: $brand-danger;
			box-shadow: none;

			&:focus, &:active{
				box-shadow: none;
			}
		}
	}
	&.has-success{
		.form-control{
			border-color: $brand-secondary;
			box-shadow: none;

			&:focus, &:active{
				box-shadow: none;
			}
		}
	}
}

.btn{
	padding: 15px 30px;
	font-family: 'Raleway', sans-serif;
	font-size: 16px;
	font-weight: 900;
	line-height: 1;
	text-transform: none;
	@include border-top-radius(0);
	@include border-bottom-radius(0);
	transition: background 0.3s ease-in-out;

	&.btn-white{
		background-color: #fff;
		border-color: #fff;
		color: $brand-secondary;

		&:hover{
			background-color: darken(#fff, 10%);
			border-color: darken(#fff, 10%);
		}
	}
	&.btn-black{
		background-color: $brand-secondary;
		border-color: $brand-secondary;
		color: #fff;

		&:hover{
			background-color: lighten($brand-secondary, 15%);
			border-color: lighten($brand-secondary, 15%);
		}
	}
	&.btn-border{
		background: none;

		&.btn-primary{
			border: $brand-primary 1px solid;
			color: $brand-primary;

			&:hover{
				background: none;
			}
		}
		&.btn-secondary{
			border: $brand-secondary 1px solid;
			color: $brand-secondary;

			&:hover{
				background: none;
			}
		}
		&.btn-tertiary{
			border: $brand-tertiary 1px solid;
			color: $brand-tertiary;

			&:hover{
				background: none;
			}
		}
		&.btn-white{
			border: #fff 1px solid;
			color: #fff;

			&:hover{
				background: none;
			}
		}
		&.btn-black{
			border: $brand-secondary 1px solid;
			color: $brand-secondary;

			&:hover{
				background: none;
			}
		}
	}
	&.btn-underlined{
		position: relative;
		padding: 5px 0;
		background: none;
		border: none;

		&:after{
			content: '';
			position: absolute;
			top: 100%; left: 0;
			display: block;
			width: calc(100% - 5px); height: 1px;
		}
		&.btn-primary{
			color: $brand-primary;

			&:after{
				background: $brand-primary;
			}
			&:hover{
				color: lighten($brand-primary, 10%);

				&:after{
					background: lighten($brand-primary, 10%);
				}
			}
			&:hover, &:focus{
				background: none;
			}
		}
		&.btn-secondary{
			color: $brand-secondary;

			&:after{
				background: $brand-secondary;
			}
			&:hover{
				color: lighten($brand-secondary, 10%);

				&:after{
					background: lighten($brand-secondary, 10%);
				}
			}
			&:hover, &:focus{
				background: none;
			}
		}
		&.btn-white{
			color: #fff;

			&:after{
				background: #fff;
			}
			&:hover{
				color: darken(#fff, 10%);

				&:after{
					background: darken(#fff, 10%);
				}
			}
			&:hover, &:focus{
				background: none;
			}
		}
	}
	&.btn-arrow{
		position: relative;
		padding: 0 12px 0 0;
		font-size: em(12);
		font-weight: 400;
		letter-spacing: 0.1em;

		&:after{
			content: '';
			position: absolute;
			top: 50%; right: 0;
			display: block;
			width: 6px; height: 10px;
			background: url('../images/arrow-btn.png') 0 0 no-repeat;
			background-size: 6px 10px;
			@include transform(0, -50%);
		}
	}
}

.checkbox label,
.radio label{
	padding-left: 0;
}

.checkbox label:after, 
.radio label:after {
    content: '';
    display: table;
    clear: both;
}

.checkbox .cr,
.radio .cr {
    position: relative;
    display: inline-block;
    background: $brand-secondary;
    border: 1px solid $brand-secondary;
    border-radius: 0;
    width: 1.3em;
    height: 1.3em;
    float: left;
    margin-right: .5em;
}

.radio .cr {
    border-radius: 50%;
}

.checkbox .cr .cr-icon,
.radio .cr .cr-icon {
    position: absolute;
    font-size: .8em;
    line-height: 0;
    top: 50%;
    left: 20%;
}

.radio .cr .cr-icon {
    margin-left: 0.04em;
}

.checkbox label input[type="checkbox"],
.radio label input[type="radio"] {
    display: none;
}

.checkbox label input[type="checkbox"] + .cr > .cr-icon,
.radio label input[type="radio"] + .cr > .cr-icon {
    transform: scale(3) rotateZ(-20deg);
    opacity: 0;
    transition: all .3s ease-in;
}

.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon,
.radio label input[type="radio"]:checked + .cr > .cr-icon {
    transform: scale(1) rotateZ(0deg);
    opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled + .cr,
.radio label input[type="radio"]:disabled + .cr {
    opacity: .5;
}

.modal-backdrop{
	background: #000;

	&.in{
		@include opacity(0.8);
	}
}
.modal{
	.modal-content{
		background: #fff;
		border: none;
		@include border-top-radius(0);
		@include border-bottom-radius(0);
		-webkit-box-shadow: 0 0 20px 0 rgba(0,0,0,0.25);
		box-shadow: 0 0 20px 0 rgba(0,0,0,0.25);

		.modal-header{
			padding: 30px 30px 15px 30px;
			text-align: center;
			border: none;

			.close{
				position: absolute;
				top: 20px; right: 20px;
				z-index: 100;
				text-align: center;
				text-shadow: none;
				background: none;
				@include opacity(1);
				outline: none;

				display: block;
				width: 14px; height: 14px;
				overflow: hidden;
				text-indent: -999px;
				background: url('../images/icon-close.png') 0 0 no-repeat;
				background-size: 14px 14px;

				&:hover, &:focus{
					text-decoration: none;
				}
				&:hover{
					@include opacity(0.75);
				}
			}
			.modal-title{
				margin: 0 0 15px 0;
				font-family: 'Podkova', serif;
				font-size: em(80);
				font-weight: 600;
				line-height: 1;
				text-align: center;
			}
		}
		.modal-body{
			padding: 0 30px 30px 30px;

			p{
				font-weight: 300;
				line-height: 2;
			}
			.brand-site{
				margin-top: 40px;
				text-align: right;
			}
			.choose-language{
				> .row{
					> div{
						margin: 10px 0;

						span{
							display: inline-block;
							margin-left: 15px;
						}
						.img-responsive{
							display: inline-block;
						}
						&.active{
							> a{
								@include opacity(0.5);
							}
						}
					}
				}
			}
		}
	}
	&#searchModal{
		z-index: 17000010;
		background-color: rgba(#000, 0.75);

		.modal-content{
			background: none;
			box-shadow: none;
			color: #fff;

			.modal-header{
				.modal-title{
					display: none;
				}
				.close{
					background-image: url('../images/icon-close2.png')
				}
			}
			.modal-body{
				position: relative;

				.form-group{
					margin: 0;

					.form-control{
						width: 100%;
						height: auto;
						font-family: 'Podkova', serif;
						font-size: 2.5em;
						font-weight: 600;
						background: none;
						border-top: none;
						border-right: none;
						border-bottom: #fff 2px solid;
						border-left: none;
						@include border-top-radius(0);
						@include border-bottom-radius(0);
						color: #fff;

						&::-webkit-input-placeholder {
							opacity: 1;
						 	color: #fff;
						}
						&::-moz-placeholder {
							opacity: 1;
							color: #fff;
						}
						&:-ms-input-placeholder {
							opacity: 1;
							color: #fff;
						}
						&:-moz-placeholder {
							opacity: 1;
							color: #fff;
						}
					}
				}
				.btn{
					position: absolute;
					top: -10px; right: 0px;
					width: auto; height: 89px;
					font-size: 36px;
					background: none;
					border: none;

					&:after{
						display: none;
					}
				}
			}
		}
	}
}

@media (min-width: $screen-sm-min) {
	.modal{
		text-align: center;
		padding: 0 !important;

		&:before {
			content: '';
			display: inline-block;
			height: 100%;
			vertical-align: middle;
			margin-right: -4px;
		}
		.modal-dialog {
			display: inline-block;
			text-align: left;
			vertical-align: middle;
		}
		.modal-content{
			.modal-header{
				padding: 60px 60px 40px 60px;

				.close{
					width: 28px; height: 28px;
					background-size: 28px 28px;
				}
				.modal-title{
				}
			}
			.modal-body{
				padding: 0 60px 60px 60px;
			}
		}
		&#searchModal{
			.modal-content{
				.modal-body{
					.btn{
						right: 45px;
					}
				}
			}
		}
	}
}

@media (min-width: $screen-md-min) {
	.btn{
		&.btn-decorated{
			margin-top: 100px;

			&:before{
				top: -60px;
			}
		}
	}

	.form{
		.checkbox{
			margin: 50px 0; 
		}
	}
}

@media (min-width: $screen-lg-min) {
	.btn{
		&.btn-border{
			position: relative;
			z-index: 1;
			-webkit-transition: color 0.3s;
			-moz-transition: color 0.3s;
			transition: color 0.3s;

			&:after {
				content: '';
				position: absolute;
				z-index: -1;
				width: 0%;
				height: 100%;
				top: 0;
				left: 0;
				-webkit-transition: width 0.3s;
				-moz-transition: width 0.3s;
				transition: width 0.3s;
			}
			&:hover:after{
				width: 100%;
			}
			&.btn-white{
				&:hover{
					color: #000;
				}
				&:after{
					background: #fff;
				}
			}
			&.btn-primary{
				&:after{
					background: $brand-primary;
				}
				&:hover, &:focus{
					background: none;
				}
				&:hover{
					color: #fff;
				}
			}
			&.btn-tertiary{
				&:after{
					background: $brand-tertiary;
				}
				&:hover, &:focus{
					background: none;
				}
				&:hover{
					color: #fff;
				}
			}
			&.btn-white{
				&:after{
					background: #fff;
				}
				&:hover, &:focus{
					background: none;
				}
				&:hover{
					color: $brand-tertiary;
				}
			}
			&.btn-black{
				&:after{
					background: $brand-secondary;
				}
				&:hover, &:focus{
					background: none;
				}
				&:hover{
					color: #fff;
				}
			}
		}
	}
}

@media (min-width: 1500px) {
	.container{
		// width: 1370px;
	}
}

@media only screen and (min--moz-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2/1),
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min-device-pixel-ratio: 2) {
	.modal .modal-content .modal-header .close{
		background-image: url('../images/icon-close@2x.png');
	}
	.modal#searchModal .modal-content .modal-header .close{
		background-image: url('../images/icon-close2@2x.png');
	}
	.custom-select:before{
		background-image: url('../images/arrow-select@2x.png');
	}
}
