.offcanvas-book-now {
    position: fixed;
    right: -100%;
    top: 0;
    z-index: 17000000;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: #fff;
    transition: right 0.3s ease-in-out;

    &._open {
        right: 0;
    }

    &__steps {
        &__step {
            // display: none;
            margin-bottom: 30px;

            /*&._active {
                display: block;
            }*/
        }
    }

    &__close {
        position: absolute;
        right: 15px;
        top: 15px;
        z-index: 20;
        width: 40px;
        height: 40px;
        font-size: 0;
        background: url('../images/icon-close2.svg') center center no-repeat;
        background-size: contain;
        border: none;
        opacity: 1;

        &:hover {
            opacity: .75;
        }
    }

    &__title {
        padding: 20px 30px;
        margin-bottom: 20px;
        font-family: 'Raleway', sans-serif;
        font-size: 20px;
        font-weight: 900;
        letter-spacing: 2px;
        border-bottom: #9AA7B2 1px solid;
        color: #9AA7B2;
    }

    &__go-back {
        padding-top: 20px;

        &__trigger {
            position: relative;
            padding: 0 20px 0 80px;
            font-family: 'Raleway', sans-serif;
            font-size: 16px;
            font-weight: 900;
            letter-spacing: 2px;
            background: none;
            border: none;
            color: #9AA7B2;

            &::before {
                content: "";
                position: absolute;
                left: 30px;
                top: 50%;
                width: 30px;
                height: 15px;
                background: url('../images/icon-back.svg') center center no-repeat;
                background-size: contain;
                transform: translateY(-50%);
            }
        }
    }

    &__book-now {
        padding: 20px 30px;

        &__date {
            margin-bottom: 15px;

            > .flex-row {
                align-items: center;

                > .flex-col {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }

            &__detail {
                margin-bottom: 15px;
                font-family: 'Raleway', sans-serif;
                font-size: 16px;
                font-weight: 700;
                letter-spacing: 2px;
                color: $brand-secondary;
            }

            &__change {
                position: relative;

                &__trigger {
                    position: relative;
                    padding-right: 30px;
                    font-family: 'Raleway', sans-serif;
                    font-size: 16px;
                    font-weight: 900;
                    letter-spacing: 2px;
                    background: none;
                    border: none;
                    color: #444B54;

                    &::after {
                        content: "";
                        position: absolute;
                        right: 0;
                        top: 50%;
                        width: 20px;
                        height: 21px;
                        background: url('../images/icon-calendar.svg') center center no-repeat;
                        background-size: contain;
                        transform: translateY(-50%);
                    }
                }

                &__datepicker {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    display: none;
                    width: 300px;
                    background: #fff;

                    &._show {
                        display: block;
                    }
                }
            }
        }

        &__title {
            margin-bottom: 20px;
            font-family: 'Raleway', sans-serif;
            font-size: 20px;
            font-weight: 900;
            color: #000;
        }

        &__price {
            margin-bottom: 20px;
            font-family: 'Raleway', sans-serif;
            font-size: 20px;
            font-weight: 900;
            color: $brand-tertiary;
        }

        &__description {
            margin-bottom: 15px;
            font-size: 14px;
        }

        &__note {
            margin-bottom: 20px;
            font-size: 14px;
        }

        &__qty {
            table {
                width: 100%;
            }

            &__item {
                &__title {
                    font-family: 'Raleway', sans-serif;
                    font-size: 20px;
                    font-weight: 900;
                }

                &__input {
                    display: flex;
                    flex-wrap: wrap;

                    input {
                        flex-basis: 0;
                        flex-grow: 1;
                        max-width: 100%;
                        width: 50px;
                        font-family: 'Raleway', sans-serif;
                        font-size: 20px;
                        font-weight: 900;
                        text-align: center;
                        background: none;
                        border: none;
                        appearance: none;
                        -moz-appearance: textfield;

                        &::-webkit-outer-spin-button,
                        &::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                    }

                    &__control {
                        flex: 0 0 30px;
                        max-width: 30px;
                        height: 30px;
                        font-family: 'Raleway', sans-serif;
                        font-size: 30px;
                        font-weight: 600;
                        line-height: 24px;
                        background: none;
                        border: #000 2px solid;
                        color: #000;
                    }
                }

                &__price {
                    font-family: 'Raleway', sans-serif;
                    font-size: 18px;
                    font-weight: 900;
                    text-align: right;
                    color: #444B54;
                }
            }
        }

        &__totals {
            text-align: right;
            margin-bottom: 30px;

            .total,
            .total-now {
                font-family: 'Raleway', sans-serif;
                font-weight: 900;

                span {
                    display: inline-block;
                    padding-left: 10px;
                    font-family: 'Spartan', sans-serif;
                }
            }

            .total {
                margin-bottom: 15px;
                font-size: 22px;

                span {
                    font-size: 24px;
                }
            }

            .total-now {
                font-size: 20px;
                font-weight: 700;
                text-transform: uppercase;

                span {
                    font-size: 22px;
                    font-weight: 900;
                    color: $brand-primary;
                }
            }
        }

        &__controls {
            margin-bottom: 30px;

            > .flex-row {
                align-items: center;

                > .flex-col {
                    flex: 0 0 100%;
                    max-width: 100%;

                    &:first-child {
                        margin-bottom: 15px;
                    }
                }
            }

            .btn {
                width: 100%;
                text-transform: uppercase;

                &.btn-tertiary {
                    background-color: #000;
                    border-color: #000;
                    color: #fff;
                }
            }
        }

        &__cta {
            font-family: 'Raleway', sans-serif;
            font-size: 20px;
            font-weight: 700;
            text-align: right;

            span {
                display: block;
                margin-top: 15px;
                font-family: 'Montserrat', sans-serif;
                font-size: 16px;
                font-weight: 400;
                color: #9AA7B2;
            }
        }
    }

    @media (min-width: $screen-sm-min) {
        right: -500px;
        max-width: 500px;
        border-left: #000 1px solid;
    }

    @media (max-width: $screen-md-min - 1) {
        &__book-now {
            &__qty {
                table {
                    tr {
                        display: flex;
                        flex-wrap: wrap;
                        margin-right: -15px;
                        margin-left: -15px;

                        td {
                            flex: 0 0 50%;
                            max-width: 50%;
                            display: block;
                            padding: 5px 15px;

                            &:first-child {
                                flex: 0 0 100%;
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    @media (min-width: $screen-md-min) {
        right: -700px;
        max-width: 700px;

        &__book-now {
            &__date {
                > .flex-row {
                    > .flex-col {
                        width: auto;

                        &:first-child {
                            flex-basis: 0;
                            flex-grow: 1;
                            max-width: 100%;
                            padding-right: 15px;
                        }

                        &:last-child {
                            flex: 0 0 auto;
                            width: auto;
                            max-width: 100%;
                            padding-left: 15px;
                        }
                    }
                }

                &__detail {
                    margin-bottom: 0;
                }

                &__change {
                    &__trigger {
                        font-size: 18px;
                    }

                    &__datepicker {
                        left: auto;
                        right: 0;
                        width: 350px;
                    }
                }
            }

            &__qty {
                table {
                    td {
                        width: 130px;
                        padding: 0 5px 30px 5px;

                        &:first-child,
                        &:last-child {
                            width: auto;
                        }
                    }
                }
            }

            &__controls {
                > .flex-row {
                    > .flex-col {
                        flex: 0 0 50%;
                        max-width: 50%;

                        &:first-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 1600px) {
        &__title {
            padding-top: 20px;
            padding-bottom: 20px;
            margin-bottom: 40px;
            font-size: 24px;
        }

        &__go-back {
            &__trigger {
                font-size: 20px;
            }
        }
    }
}

body.offcanvas-book-now--shown {
    .wrapper {
        &::after {
            content: "";
            position: fixed;
            left: 0;
            top: 0;
            z-index: 16500000;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.5);
        }
    }
}

