.accomodation-card {
    position: relative;
    border: #000 1px solid;

    &__link {
        display: block;
        color: #000;

        &:hover {
            text-decoration: none;
            color: #000;
        }
    }

    &__image {
        position: relative;
        overflow: hidden;

        .img-responsive {
            width: 100%;
        }
    }

    &__content {
        padding: 15px;
        text-align: center;
    }

    &__title {
        position: relative;
        z-index: 10;
        display: block;
        padding: 15px;
        margin-top: -15px;
        font-family: 'Raleway', sans-serif;
        font-size: 20px;
        font-weight: 900;
        background: #000;
        color: #fff;
        transform: translateY(-50%);
    }

    &__text {
        display: block;
        margin-bottom: 20px;
        font-size: 16px;
        text-align: left;
    }

    &__details {
        padding-right: 15px;
        padding-left: 15px;
        margin-bottom: 20px;
        text-align: left;
    }

    &__cta {
        font-family: 'Raleway', sans-serif;
        font-size: 20px;
        font-weight: 900;
        text-transform: uppercase;
    }
}
