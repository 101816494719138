.header{
	.navbar-default{
		left: 50%;
		margin: 0;
		width: 100%;
		max-width: 1920px;
		background: rgba(255, 255, 255, 0.5);
		border-bottom: #000 1px solid;
		@include border-top-radius(0);
		@include border-bottom-radius(0);
		transition: top 0.2s ease-in-out, background 0.2s ease-in-out;
		transform: translateX(-50%);

		&.fixed{
			position: fixed;
			top: 0; bottom: auto;
		}
		&.not-fixed{
			top: 0 !important;
		}
		&.nav-up{
			/* top: -90px; */

			-webkit-box-shadow: none;
			box-shadow: none;
			background: #fff;
		}
		&.nav-down{
			top: 0;
			background: #fff;
		}
		> .container-fluid{
			position: relative;

			.navbar-header{
				position: relative;
				z-index: 20;
				height: 58px;

				.navbar-toggle{
					position: absolute;
					top: 20px; right: 15px;
					width: 20px; height: 17px;
					padding: 0;
					margin: 0;
					text-align: left;
					border: none;
					transform: scaleX(-1);
					@include border-top-radius(0);
					@include border-bottom-radius(0);

					&:before{
						content: '';
						position: absolute;
						bottom: -28px; left: 0;
						display: block;
						width: 15px; height: 15px;
						background: $brand-tertiary;
						@include rotate(45deg);
					}
					&.collapsed{
						&:before{
							display: none;
						}
					}
					.icon-bar{
						width: 20px; height: 2px;
						background: $brand-primary;
						@include border-top-radius(0);
						@include border-bottom-radius(0);

						&+.icon-bar{
							margin-top: 5px;
						}
						&:nth-child(3){
							width: 12px;
						}
						&:nth-child(4){
							width: 16px;
						}
					}
					&:hover, &:focus{
						background: none;

						.icon-bar{
							background: $brand-primary;
						}
					}
				}
				.navbar-brand{
					position: absolute;
					top: 10px; left: 15px;
					display: block;
					height: auto;
					padding: 0;

					.img-responsive{
						display: inline-block;
						width: 125px;
						max-width: inherit;
					}
				}
				.navbar-search{
					position: absolute;
					top: 15px; right: 160px;
					display: block;
					width: 30px; height: 30px;
					overflow: hidden;
					text-indent: -999px;
					background: url('../images/icon-search-mobile.svg') center center no-repeat;
					background-size: contain;
					color: #fff;

					&:hover, &:focus{
						text-decoration: none;
					}
				}
				.navbar-cart{
					position: absolute;
					top: 15px; right: 115px;
					display: block;
					width: 30px; height: 30px;
					overflow: hidden;
					text-indent: -999px;
					background: url('../images/icon-cart-mobile.svg') center center no-repeat;
					background-size: contain;
					color: #fff;

					&:hover, &:focus{
						text-decoration: none;
					}

					span {
						position: absolute;
						right: 0;
						bottom: 0;
						width: 15px;
						height: 15px;
						font-size: 10px;
						line-height: 15px;
						text-align: center;
						text-indent: initial;
						background-color: $brand-primary;
						border-radius: 100%;
						color: #fff;
					}
				}

				.navbar-language {
					position: absolute;
					top: 18px; right: 50px;

					.dropdown-toggle {
						display: flex;
						font-weight: 700;
						text-decoration: none;
						text-transform: uppercase;
						color: #000;

						img {
							width: 22px;
							margin-right: 5px;
						}
					}

					.dropdown-menu {
						left: auto;
						right: 0;
						padding: 5px 0;
						margin-top: 10px;
						background: $brand-secondary;
						border: none;
						box-shadow: none;
						@include border-top-radius(0);
						@include border-bottom-radius(0);

						&:before{
							content: '';
							position: absolute;
							top: -7px; right: 15px;
							display: block;
							width: 16px; height: 16px;
							margin-left: -8px;
							background: $brand-secondary;
							@include rotate(45deg);
						}
						> li{
							position: relative;
							z-index: 20;

							> a,
							> .title {
								padding: 5px 15px;
								color: #fff;

								&:hover, &:focus{
									background: none;
								}
							}
						}
					}
				}
			}
			.navbar-collapse{
				position: relative;
				z-index: 30;
				max-height: calc(100vh - 58px);
				background: $brand-tertiary;
				border: none;
				box-shadow: none;

				> .navbar-nav{
					> li {
						> a {
							&:hover{
								color: $brand-primary;
							}
						}
					}
					li{
						> a,
						> .title {
							font-weight: 700;
							color: #fff;

							&:hover, &:focus{
								text-decoration: none;
							}
							.glyphicon{
								font-size: em(12);
							}
						}
						&.active, &.open{
							> a,
							> .title {
								background: none;
							}
						}
						&.active{
							> a,
							> .title {
								background: none;
								color: #404040;
							}
						}
					}
					&.primary-navbar{
						li{
							> a,
							> .title {
								font-size: em(20);
							}
						}
					}
					&.secondary-navbar{
						li{
							> a,
							> .title{
								font-size: em(12);
								text-transform: uppercase;
							}
						}
					}

					.dropdown-menu {
						> li {
							> ul {
								padding: 0;
								margin: 0;
								list-style: none;

								a,
								.title {
									display: block;
									padding: 5px 15px 5px 25px;
								}
							}

							&.double {
								padding-bottom: 30px;

								a,
								.title {
									display: block;
									padding: 5px 15px 5px 25px;
								}
							}

							.title {
								text-decoration: underline;
								text-transform: uppercase;
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: $screen-sm-min) {
	.header{
		.navbar-default{
			> .container-fluid{
				.navbar-collapse{
					float: right;
					width: 300px;
				}
			}
		}
	}
}

@media (min-width: $screen-md-min) {
	.header{
		.navbar-default{
			&.nav-up{
				/* top: -145px; */
			}
			&.nav-down{
				&:before{
					/* background: rgba(#000, 0.5); */
				}
			}
			> .container-fluid{
				position: relative;
				height: 85px;

				.navbar-header{
					position: static;
					height: auto;

					.navbar-brand{
						top: 50%;
						padding-right: 15px !important;
						margin: 0;
						border-right: #000 1px solid;
						transform: translateY(-50%);

						.img-responsive{
							width: 135px;
						}
					}
					.navbar-search{
						top: 15px; right: 15px;
						width: 55px;
						height: 55px;
						background-image: url('../images/icon-search.svg');
					}
					.navbar-cart{
						display: none;
					}
					.navbar-language {
						display: none;
					}
				}
				.navbar-collapse{
					float: none;
					position: static;
					width: auto;
					max-height: inherit;
					background: none;

					> .navbar-nav{
						> li{
							> a {
								text-transform: uppercase;
								color: #000;
							}
							.dropdown-menu{
								left: 50%;
								padding: 5px 0;
								background: $brand-secondary;
								border: none;
								box-shadow: none;
								@include transform(-50%, 0);
								@include border-top-radius(0);
								@include border-bottom-radius(0);

								&:before{
									content: '';
									position: absolute;
									top: -7px; left: 50%;
									display: block;
									width: 16px; height: 16px;
									margin-left: -8px;
									background: $brand-secondary;
									@include rotate(45deg);
								}
								> li{
									position: relative;
									z-index: 20;

									> a,
									> .title {
										padding: 5px 15px;
										color: #fff;

										&:hover, &:focus{
											background: none;
										}
									}
								}

								> li {
									&.double {
										width: 400px;
										@include clearfix;

										> ul {
											float: left;
											width: 200px;
										}
									}
								}
							}
						}
						&.primary-navbar{
							position: absolute;
							bottom: 0; left: 170px;

							> li{
								> a,
								> .title {
									padding: 15px 10px;
									font-family: 'Raleway', sans-serif;
									font-size: 14px;
									font-weight: 900;
								}
								&.active{
									> a,
									> .title {
										color: $brand-primary;
									}
								}
								.dropdown-toggle {
									.caret {
										display: none;
									}
								}
								.dropdown-menu{
									> li{
										a,
										.title {
											font-size: em(12);
										}
									}
								}
							}
						}
						&.secondary-navbar{
							position: absolute;
							top: 5px; left: 165px;

							> li{
								> a,
								.title {
									padding-top: 10px;
									padding-bottom: 10px;
									font-size: 12px;
									font-weight: 700;
								}
								.dropdown-menu{
									> li{
										a,
										.title {
											font-size: em(9);
										}
									}
								}
							}
						}
					}

					.navbar-quick-links {
						position: absolute;
						right: 180px;
						top: 5px;
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						margin-right: -15px;
						margin-left: -15px;

						> div {
							flex: 0 0 auto;
							width: auto;
							max-width: 100%;
							padding-right: 15px;
							padding-left: 15px;
						}

						.dropdown {
							&--languages {
								.dropdown-toggle {
									display: flex;
									flex-wrap: wrap;
									align-items: center;
									font-size: 12px;
									font-weight: 700;
									text-decoration: none;
									text-transform: uppercase;
									color: #000;

									img {
										width: 27px;
										margin-right: 10px;
									}
								}
							}
						}

						.dropdown-menu{
							left: 50%;
							padding: 5px 0;
							margin-top: 10px;
							background: $brand-secondary;
							border: none;
							box-shadow: none;
							@include transform(-50%, 0);
							@include border-top-radius(0);
							@include border-bottom-radius(0);

							&:before{
								content: '';
								position: absolute;
								top: -7px; left: 50%;
								display: block;
								width: 16px; height: 16px;
								margin-left: -8px;
								background: $brand-secondary;
								@include rotate(45deg);
							}
							> li{
								position: relative;
								z-index: 20;

								> a,
								> .title {
									padding: 5px 15px;
									color: #fff;

									&:hover, &:focus{
										background: none;
									}
								}
							}
						}

						&__item {
							&__link {
								display: flex;
								align-items: center;
								padding: 0;
								font-size: 12px;
								font-weight: 700;
								text-decoration: none;
								text-transform: uppercase;
								background: none;
								border: none;
								color: #000;

								img {
									width: 24px;
									margin-right: 5px;
								}
							}
							&--cart {}
						}
					}
				}
			}
		}
	}
}

@media (min-width: $screen-lg-min) {
	.header{
		.navbar-default{
			> .container-fluid{
				.navbar-collapse{
					> .navbar-nav{
						&.primary-navbar{
							> li{
								> a,
								> .title {
									font-size: 16px;
								}
								.dropdown-toggle {
									.caret {
										font-size: 16px;
									}
								}
								.dropdown-menu{
									> li{
										a,
										.title {
											font-size: em(16);
										}
									}
								}
							}
						}
						&.secondary-navbar{
							> li{
								> a,
								> .title {
									font-size: 14px;
								}
								.dropdown-menu{
									> li{
										a,
										.title {
											font-size: em(12);
										}
									}
								}
							}
						}
					}

					.navbar-quick-links {
						.dropdown {
							&--languages {
								.dropdown-toggle {
									font-size: 14px;
								}
							}
						}

						&__item {
							&__link {
								font-size: 14px;
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: 1600px) {
	.header{
		.navbar-default{
			> .container-fluid{
				.navbar-header {
					.navbar-brand {
						.img-responsive {
							width: 190px;
						}
					}
				}

				.navbar-collapse{
					> .navbar-nav{
						&.primary-navbar{
							left: 217px;

							> li{
								> a,
								> .title {
									padding-left: 20px;
									padding-right: 20px;
									font-size: 18px;
								}
							}
							.dropdown-menu{
								> li{
									a,
									.title {
										font-size: em(20);
									}
								}
							}
						}
						&.secondary-navbar {
							left: 220px;

							> li{
								> a,
								> .title {
									font-size: 16px;
								}
							}
						}
					}

					.navbar-quick-links {
						.dropdown {
							&--languages {
								.dropdown-toggle {
									font-size: 16px;
								}
							}
						}

						&__item {
							&__link {
								font-size: 16px;
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: 1800px) {
	.header {
		.navbar-default {
			> .container-fluid {
				.navbar-header {
					.navbar-brand {
						padding-right: 30px !important;
					}
				}

				.navbar-collapse {
					> .navbar-nav {
						&.primary-navbar {
							left: 245px;
						}

						&.secondary-navbar {
							left: 245px;
						}
					}
				}
			}
		}
	}
}

div.kkcontainer {
	position: relative;
	padding-top: 100px;
	padding-right: 15px;
	padding-bottom: 30px;
	padding-left: 15px;
	margin-top: 0;

	&::after {
		content: "";
		position: absolute;
		left: 50%;
		bottom: 0;
		width: 100vw;
		max-width: 1920px;
		height: 1px;
		background: #000;
		transform: translateX(-50%);
	}

	@media (min-width: $screen-lg-min) {
		padding-top: 150px;
		padding-bottom: 60px;
	}

	@media (min-width: 1500px) {
		width: 1370px;
	}

	@media (min-width: 1600px) {
		padding-right: 60px;
		padding-left: 60px;

		> .row {
			margin-right: -60px;
			margin-left: -60px;

			> div {
				padding-right: 60px;
				padding-left: 60px;
			}
		}
	}
}
