.categoria-2022 {
    .page-info {
        margin-bottom: 30px;

        > h1 {
            display: block;
            margin-bottom: 30px;
            font-family: 'Raleway', sans-serif;
            font-size: 24px;
            font-weight: 900;
            line-height: 1.5;
            letter-spacing: 3px;
            text-transform: uppercase;
            color: #000;

            > span {
                background-image: repeating-linear-gradient(to bottom, transparent, transparent 50%, silver 50%, silver);
            }
        }

        > div {
            font-size: 14px;
            line-height: 1.5;
        }
    }

    .list-products {
        .flex-row {
            > .flex-col {
                flex: 0 0 100%;
                max-width: 100%;
                margin-bottom: 30px;

                > div {
                    height: 100%;
                }
            }
        }
    }

    @media (min-width: $screen-md-min) {
        .page-info {
            margin-bottom: 50px;

            > h1 {
                margin-bottom: 50px;
                font-size: 30px;
                line-height: 1.5;
            }
        }

        .list-products {
            &__description {
                font-size: 18px;
            }

            .flex-row {
                > .flex-col {
                    flex: 0 0 50%;
                    max-width: 50%;
                }
            }
        }
    }

    @media (min-width: 1600px) {
        .list-products {
            .flex-row {
                margin-right: -30px;
                margin-left: -30px;

                > .flex-col {
                    padding-right: 30px;
                    padding-left: 30px;
                    margin-bottom: 60px;
                }
            }
        }
    }
}
