.frontpage-2022 {
    &__top-slider {
        .top-slider {
            > .container-fluid {
                .slick {
                    .slider-item {
                        > div {
                            > .caption {
                                padding: 0;
                                bottom: 50%;
                                transform: translateY(50%);

                                .btn {
                                    padding: 0;
                                    font-family: 'Raleway', sans-serif;
                                    font-weight: 900;
                                    text-shadow: 2px 2px 5px rgba(0,0,0,0.5);
                                    text-transform: none;
                                    background: none;
                                    white-space: initial;

                                    &::after {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__ny-experiences {
        .ny-experiences {
            position: relative;
            overflow: initial;

            &:before {
                display: none;
            }

            .search-experience {
                position: absolute;
                bottom: 100%;
                margin: 0;
                background: none;

                > .container {
                    > .row {
                        > div {
                            &:first-child {
                                .custom-select {
                                    background: #000;
                                    border: #fff 1px solid;
                                }
                            }

                            &:nth-child(2) {
                                .custom-select {
                                    background: #000;
                                    border: #fff 1px solid;
                                }
                            }
                        }
                    }
                }

                .custom-select {
                    text-align: center;
                    text-transform: uppercase;

                    &::before,
                    &::after {
                        display: none;
                    }

                    select {
                        padding-right: 15px;
                        padding-left: 15px;
                        font-size: 16px;
                        text-align: center;
                        text-transform: uppercase;
                    }

                    .form-control {
                        font-weight: 700;
                    }
                }

                .btn-primary {
                    height: 64px;
                    font-family: Montserrat,sans-serif;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 64px;
                    border: #000 1px solid;
                }
            }

            .ny-experience {
                .title {
                    position: relative;
                    padding-top: 60px;
                    padding-bottom: 60px;
                    margin-bottom: 10px !important;
                    font-family: 'Raleway', sans-serif !important;
                    font-size: 26px !important;
                    font-weight: 100 !important;
                    line-height: 1.5 !important;
                    text-align: center;
                    text-transform: uppercase !important;

                    &::before {
                        content: "";
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        display: block;
                        width: 200px;
                        height: 200px;
                        background: url('../images/logo-symbol.svg') center center no-repeat;
                        background-size: contain;
                        opacity: 0.05;
                        transform: translate(-50%, -50%);
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        right: 0;
                        top: -20px;
                        display: block;
                        width: 100px;
                        height: 85px;
                        background: url('../images/icon-plane.svg') center center no-repeat;
                        background-size: contain;
                    }
                }

                .experiences-cats {
                    > .row {
                        > div {
                            > a {
                                &:before {
                                    display: none !important;
                                }

                                &:after {
                                    display: none !important;
                                }

                                .category-heading {
                                    position: absolute;
                                    left: 0;
                                    top: 50%;
                                    z-index: 50;
                                    width: 100%;
                                    padding: 0 15px;
                                    text-align: center;
                                    transform: translateY(-50%);

                                    img {
                                        margin-top: 10px;
                                    }
                                }

                                .category-title {
                                    display: block;
                                    position: static !important;
                                    padding: 0 !important;
                                    margin-bottom: 10px;
                                    font-size: 30px !important;
                                    font-family: 'Raleway', sans-serif !important;
                                    font-weight: 700 !important;
                                    text-shadow: 2px 2px 5px rgba(0,0,0,0.5);
                                }

                                .category-subtitle {
                                    display: block;
                                    font-family: 'Raleway', sans-serif;
                                    font-size: 16px;
                                    font-weight: 700;
                                    text-shadow: 2px 2px 5px rgba(0,0,0,0.5);
                                    color: #fff;
                                }

                                .category-availability {
                                    display: none !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__recommendations {
        &__title {
            display: block;
            margin-bottom: 30px;
            font-family: 'Raleway', sans-serif;
            font-size: 20px;
            font-weight: 900;
            letter-spacing: 3px;
            text-align: center;
            text-transform: uppercase;

            > span {
                position: relative;
                z-index: 10;
                background-image: repeating-linear-gradient(to bottom, transparent, transparent 50%, #C0C0C0 50%, #C0C0C0);
            }
        }

        &__giftcard {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 30px 15px;
            margin-bottom: 80px;
            background: #000000;
            color: #fff;

            &__link {
                display: block;
                color: #fff;

                &:hover {
                    text-decoration: none;
                    color: #fff;
                }
            }

            &__logo,
            &__icon {
                text-align: center;

                img {
                    display: inline-block;
                }
            }

            &__logo {
                margin-bottom: 15px;
            }

            &__icon {
                margin-top: 15px;
            }

            &__title,
            &__cta {
                display: block;
                font-weight: 900;
                text-align: center;
            }

            &__title {
                margin-bottom: 15px;
                text-transform: uppercase;
                font-size: 30px;
            }

            &__cta {
                font-size: 24px;
            }
        }

        &__scroll {
            overflow-x: auto;
            margin-right: -15px;
            margin-bottom: 30px;
            margin-left: -15px;

            .os-scrollbar {
                position: static !important;
                padding: 0 15px !important;
                margin-top: 15px !important;
            }
        }

        &__list {
            display: flex;
            flex-flow: row nowrap;

            &__item {
                flex: 0 0 auto;
                width: auto;
                max-width: 100%;
                padding-right: 15px;
                padding-left: 15px;

                > div {
                    width: 275px;
                    height: 100%;
                }

                .giftcard {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__partners {
        padding-top: 35px;
        padding-bottom: 35px;
        margin-bottom: 60px;
        font-size: 18px;
        text-align: center;
        border-bottom: #000 1px solid;

        &__title {
            display: block;
            margin-bottom: 20px;
            font-family: 'Raleway', sans-serif;
            font-size: 28px;
            font-weight: 900;
            letter-spacing: 3px;
            text-align: center;
        }

        &__quote {
            margin-bottom: 15px;
        }

        &__quote-author {
            margin-bottom: 30px;
            text-transform: uppercase;
        }

        &__collaborate {
            margin-bottom: 5px;
            font-weight: 700;
            text-transform: uppercase;
        }

        &__collaborate-cta {
            text-transform: uppercase;

            a {
                font-family: 'Brittany', serif;
                font-size: 30px;
                font-weight: 400;
                text-transform: none;
                color: #000;
            }
        }

        &__list-scroll {
            margin-right: -15px;
            margin-left: -15px;
            overflow: auto;
        }

        &__list {
            display: flex;
            flex-flow: row nowrap;
            margin-bottom: 40px;

            &::before,
            &::after {
                content: "";
                margin-right: auto;
                margin-left: auto;
            }

            &__item {
                flex: 0 0 auto;
                width: auto;
                max-width: 100%;
                padding-right: 15px;
                padding-left: 15px;

                > div {
                    width: 130px;
                    overflow: hidden;
                    border-radius: 100%;
                }
            }
        }
    }

    &__news {
        margin-bottom: 60px;

        &__title {
            display: block;
            margin-bottom: 20px;
            font-family: 'Raleway', sans-serif;
            font-size: 20px;
            font-weight: 900;
            letter-spacing: 3px;
            text-align: center;
            text-transform: uppercase;

            > span {
                position: relative;
                z-index: 10;
                background-image: repeating-linear-gradient(to bottom, transparent, transparent 50%, silver 50%, silver);
            }
        }

        > .container-fluid {
            > .flex-row {
                > .flex-col {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }

        &__exclusives {
            display: flex;
            flex-wrap: nowrap;
            overflow-x: auto;
            margin-right: -15px;
            margin-bottom: 45px;
            margin-left: -15px;

            &__item {
                flex: 0 0 75vw;
                max-width: 75vw;
                margin-right: 15px;
                margin-bottom: 1px;
                border: #77838D 1px solid;

                > div {
                    height: 100%;
                }

                &__content {
                    padding: 15px;
                }

                &__image {
                    img {
                        width: 100%;
                    }
                }

                &__title {
                    display: block;
                    font-family: 'Raleway', sans-serif;
                    font-size: 18px;
                    font-weight: 900;
                    letter-spacing: 2px;

                    a {
                        color: #000;
                    }
                }

                &__subtitle {
                    font-size: 18px;
                }

                &__cta {
                    margin-top: 15px;
                    text-align: right;

                    .btn {
                        padding: 0;
                        color: $brand-secondary;
                    }
                }
            }
        }

        &__list {
            &__item {
                margin-bottom: 45px;

                > .flex-row {
                    margin-bottom: 15px;

                    > .flex-col {
                        &:first-child {
                            flex-basis: 0;
                            flex-grow: 1;
                            max-width: 100%;
                        }

                        &:last-child {
                            flex: 0 0 auto;
                            width: auto;
                        }
                    }
                }

                &__title {
                    display: block;
                    font-family: 'Raleway', sans-serif;
                    font-size: 18px;
                    font-weight: 700;
                    letter-spacing: 2px;

                    a {
                        color: #000;
                    }
                }

                &__date {
                    display: block;
                    font-family: 'Spartan', sans-serif;
                    font-size: 16px;
                    font-weight: 900;
                    color: #9AA7B2;
                }

                &__text {
                    font-size: 16px;
                }

                &__cta {
                    margin-top: 15px;

                    .btn {
                        padding: 0;
                        color: $brand-tertiary;
                    }
                }
            }
        }

        &__see-all {
            text-align: right;

            .btn {
                text-transform: uppercase;
                border-radius: 5px;
                border-width: 3px !important;
            }
        }
    }

    &__infos {
        padding-top: 75px;
        padding-bottom: 6px;
        margin-bottom: 60px;
        overflow-x: auto;

        > .container {
            > .flex-row {
                margin-right: -15px;
                margin-left: -15px;

                > .flex-col {
                    padding-right: 15px;
                    padding-left: 15px;
                }
            }
        }

        &__item {
            position: relative;
            height: 100%;
            padding: 50px 30px 30px 30px;
            border: #C7C7C7 1px solid;
            border-radius: 3px;

            &__image {
                position: absolute;
                left: 50%;
                top: 0;
                width: 75px;
                height: 75px;
                overflow: hidden;
                border-radius: 100%;
                transform: translate(-50%, -50%);
            }

            &__text {
                h1, h2, h3, h4, h5, h6 {
                    font-weight: 700;
                    color: #515152;
                }

                h1, h2, h3, h4 {
                    font-size: 20px;
                }

                h5, h6 {
                    font-size: 14px;
                }

                font-size: 14px;
            }
        }
    }

    &__social {
        padding-bottom: 60px;

        > .container {
            > .flex-row {
                > .flex-col {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }

        &__heading {
            > .flex-row {
                align-items: center;

                > .flex-col {
                    &:last-child {
                        flex: 0 0 20%;
                        max-width: 20%;
                    }
                }
            }

            &__title {
                position: relative;
                z-index: 10;
                display: inline-block;
                margin-bottom: 30px;
                font-family: 'Raleway', sans-serif;
                font-size: 30px;
                font-weight: 900;
                letter-spacing: 2px;
                text-transform: uppercase;

                > span {
                    vertical-align: middle;
                    background-image: repeating-linear-gradient(to bottom, transparent, transparent 50%, #C0C0C0 50%, #C0C0C0);

                    span {
                        font-family: 'Brittany', serif;
                        font-size: 42px;
                        font-weight: 400;
                        letter-spacing: initial;
                        text-transform: none;
                    }
                }
            }

            &__subtitle {
                display: block;
                font-family: 'Raleway', sans-serif;
                font-size: 16px;
                font-weight: 900;
                text-transform: uppercase;

                span {
                    font-family: 'Spartan', sans-serif;
                    font-size: 20px;
                    font-weight: 900;
                    color: #0052A5;
                }
            }
        }

        &__videos {
            padding-left: 15px;
            margin-right: -15px;
            margin-left: -15px;
            margin-bottom: 60px;
            overflow-x: auto;

            > .flex-row {
                align-items: baseline;
                flex-wrap: initial;
                margin-right: 0;
                margin-left: 0;

                > .flex-col {
                    min-width: 275px;
                }
            }

            .video {
                position: relative;

                .vjs-theme-nywelcome {
                    overflow: hidden;
                    border-radius: 30px;
                }

                &.facebook,
                &.instagram {
                    position: relative;

                    &::before {
                        content: "";
                        position: absolute;
                        z-index: 20;
                        display: block;
                    }
                }

                &.facebook {
                    padding-top: 125px;

                    &::before {
                        left: 50%;
                        top: 0;
                        width: 80px;
                        height: 289px;
                        background: url('../images/icon-likes.png') 0 0 no-repeat;
                        background-size: contain;
                    }
                }

                &.instagram {
                    padding-top: 80px;

                    &::before {
                        left: 50%;
                        top: 0;
                        width: 98px;
                        height: 80px;
                        transform: translateX(-50%);
                        background: url('../images/icon-followers.png') 0 0 no-repeat;
                        background-size: contain;
                    }
                }
            }
        }

        &__links {
            &__title {
                display: block;
                margin-bottom: 45px;
                font-family: 'Raleway', sans-serif;
                font-size: 24px;
                font-weight: 900;
                letter-spacing: 6px;
                line-height: 1;
                text-align: center;
                text-transform: uppercase;
            }

            &__list {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;

                a {
                    flex: 0 0 auto;
                    width: auto;
                    max-width: 100%;
                    margin: 0 15px 15px 15px;
                }
            }
        }

        &__yt-video {
            margin-right: -15px;
            margin-bottom: 45px;
            margin-left: -15px;

            &__title {
                margin-bottom: 45px;
                text-align: center;

                img {
                    display: inline-block;
                    max-width: 50%;
                }
            }
        }

        &__ig-stories {
            margin-bottom: 45px;

            &__title {
                position: relative;
                z-index: 20;
                display: flex;
                justify-content: center;
                font-family: 'Brittany', serif;
                font-size: 60px;
                font-weight: 400;
                line-height: 1;
                text-align: center;

                div {
                    flex: 0 0 auto;
                    width: auto;
                    max-width: 100%;
                    margin-right: 15px;
                    margin-bottom: -10px;
                }

                span {
                    flex: 0 0 auto;
                    width: auto;
                    max-width: 100%;

                    span {
                        display: block;
                        transform: rotate(-5deg);
                    }
                }
            }

            &__list {
                padding: 40px 15px 15px 15px;
                margin-right: -15px;
                margin-left: -15px;
                background: #000;
                color: #fff;

                &__item {
                    overflow: hidden;
                    border-radius: 10px;

                    img {
                        width: 100%;
                    }
                }
            }

            &__subtitle {
                padding: 15px;
                margin-right: -15px;
                margin-left: -15px;
                font-family: 'Raleway', sans-serif;
                font-size: 20px;
                font-weight: 900;
                text-align: center;
                text-transform: uppercase;
                background: #000;
                color: #fff;

                img {
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }
    }

    &__numbers {
        padding-top: 35px;
        padding-bottom: 70px;
        background: #000;
        color: #fff;

        &__title {
            display: block;
            margin-bottom: 30px;
            font-family: 'Raleway', sans-serif;
            font-size: 30px;
            font-weight: 900;
            letter-spacing: 2px;
            text-align: center;

            span {
                font-family: 'Brittany', serif;
                font-size: 60px;
                font-weight: 400;
                letter-spacing: initial;
                text-transform: none;
                color: $brand-primary;
            }
        }

        &__list {
            > .flex-row {
                align-items: center;
                justify-content: center;
                margin-right: -15px;
                margin-left: -15px;

                > .flex-col {
                    flex: 0 0 33.3333%;
                    max-width: 33.3333%;
                    padding-right: 15px;
                    padding-left: 15px;
                }
            }

            &__item {
                position: relative;
                overflow: hidden;
                max-width: 200px;
                margin: 0 auto;
                background: #fff;
                border-radius: 100%;
                color: #000;

                &::before {
                    content: '';
                    display: block;
                    padding-top: 100%;
                }

                > div {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    width: 100%;
                    font-size: 11px;
                    font-weight: 900;
                    text-align: center;
                    text-transform: uppercase;
                    transform: translateY(-50%);

                    .number {
                        display: block;
                        font-size: 16px;
                    }
                }

                &--red {
                    background: $brand-primary;
                    color: #fff;
                }
            }
        }
    }

    @media (max-width: $screen-md-min - 1) {
        &__top-slider {
            .top-slider {
                > .container-fluid {
                    .slick {
                        .slider-item {
                            > div {
                                height: 400px;

                                > .caption {
                                    .btn {
                                        font-size: 20px;
                                    }
                                }
                            }
                        }

                        .slick-dots {
                            display: none !important;
                        }

                        .slick-arrow {
                            position: absolute;
                            bottom: 15px;
                            z-index: 100;
                            width: 30px;
                            height: 30px;
                            padding: 0;
                            font-size: 0;
                            background: url('../images/icon-arrow3.svg') center center no-repeat;
                            background-size: contain;
                            border: none;

                            &.slick-prev {
                                left: 15px;
                                transform: scaleX(-1);
                            }

                            &.slick-next {
                                right: 15px;
                            }
                        }
                    }
                }
            }
        }

        &__ny-experiences {
            .ny-experiences {
                display: flex;
                flex-wrap: wrap;
                padding-top: 120px;

                .ny-experience,
                .prefooter__plus,
                .prefooter__secure-booking {
                    width: 100%;
                }

                .ny-experience {
                    order: 3;

                    .experiences-cats {
                        margin-right: -15px;
                        margin-left: -15px;
                        overflow-x: auto;

                        > .row {
                            display: flex;
                            flex-flow: row nowrap;
                            margin: 0;

                            > div {
                                flex: 0 0 75vw;
                                width: 75vw;
                                max-width: 100%;

                                > a {
                                    margin-bottom: 0 !important;

                                    .category-title {
                                        font-size: 24px !important;
                                    }
                                }
                            }
                        }
                    }
                }

                .prefooter__plus {
                    order: 2;
                    padding-top: 35px;
                    padding-bottom: 35px;
                    margin-bottom: 35px;
                    background: $brand-quaternary;

                    .slick-dots {
                        margin-bottom: 0;
                    }
                }

                .prefooter__secure-booking {
                    order: 1;
                }

                .search-experience {
                    left: 50%;
                    transform: translate(-50%, 50%);
                    width: calc(100% - 90px);
                    padding: 0;

                    .container {
                        width: auto;
                    }
                }
            }
        }

        &__infos {
            > .container {
                width: 275vw;
            }
        }
    }

    @media (min-width: $screen-md-min) {
        &__top-slider {
            .top-slider {
                > .container-fluid {
                    .slick {
                        .slider-item {
                            > div {
                                height: 500px;

                                > .caption {
                                    .btn {
                                        font-size: 40px;
                                        text-align: inherit;
                                    }
                                }
                            }
                        }

                        .slick-dots {
                            @include list-unstyled();

                            position: absolute;
                            right: 40px;
                            top: 50%;
                            z-index: 100;
                            width: 15px;
                            margin: 0;
                            transform: translateY(-50%);

                            > li {
                                width: 100%;
                                margin: 10px 0;

                                button {
                                    width: 15px;
                                    height: 15px;
                                    padding: 0;
                                    font-size: 0;
                                    background: #fff;
                                    border: none;
                                    border-radius: 3px;
                                }

                                &.slick-active {
                                    button {
                                        background: $brand-primary;
                                    }
                                }
                            }

                        }

                        .slick-arrow {
                            display: none !important;
                        }
                    }
                }
            }
        }

        &__ny-experiences {
            .ny-experiences {
                &:before {
                    display: none;
                }

                .ny-experience {
                    margin-bottom: 120px;

                    .title {
                        padding-right: 30px;
                        padding-left: 30px;
                        margin-bottom: 30px !important;
                        font-size: 40px !important;

                        &::after {
                            right: 0;
                            top: 50%;
                            width: 198px;
                            height: 168px;
                        }
                    }

                    .experiences-cats {
                        padding-top: 76px;
                    }
                }

                .search-experience {
                    width: 100%;
                    padding: 0 0 30px 0;

                    .row {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: center;

                        > div {
                            flex: 0 0 33.3333%;
                            max-width: 33.3333%;

                            &:last-child {
                                flex: 0 0 20%;
                                max-width: 20%;
                            }
                        }
                    }

                    .btn-primary {
                        border-color: #fff;
                    }
                }
            }
        }

        &__recommendations {
            margin-bottom: 90px;

            &__title {
                padding-right: 60px;
                padding-left: 60px;
                margin-bottom: 45px;
                font-size: 40px;
            }

            &__giftcard {
                padding: 30px;
                background: $brand-primary;

                &__title {
                    margin-bottom: 30px;
                }

                &__logo {
                    margin-bottom: 30px;
                }
            }

            &__scroll {
                .os-scrollbar {
                    padding: 0 75px !important;
                }
            }

            &__list {
                &__item {
                    padding-right: 30px;
                    padding-left: 30px;

                    &:first-child {
                        padding-left: 75px;
                    }

                    &:last-child {
                        padding-right: 75px;
                    }

                    > div {
                        width: 350px;
                    }
                }
            }
        }

        &__partners {
            padding-top: 60px;
            padding-bottom: 60px;
            margin-bottom: 120px;

            &__title {
                margin-bottom: 30px;
                font-size: 42px;
            }

            &__list {
                &__item {
                    padding-right: 30px;
                    padding-left: 30px;
                }
            }
        }

        &__news {
            margin-bottom: 120px;

            &__title {
                margin-bottom: 45px;
                font-size: 42px;
            }

            > .container-fluid {
                > .flex-row {
                    align-items: center;
                    border-top: #77838D 1px solid;
                    border-bottom: #77838D 1px solid;

                    > .flex-col {
                        flex: 0 0 50%;
                        max-width: 50%;
                    }
                }
            }

            &__exclusives {
                flex-wrap: wrap;
                overflow-x: initial;
                margin-bottom: 0;

                &__item {
                    flex: 0 0 50%;
                    max-width: 50%;
                    margin-right: 0;
                    margin-bottom: 0;
                    border-left: none;
                    border-top: none;

                    &:nth-child(n+3) {
                        border-bottom: none;
                    }

                    &__title {
                        font-size: 25px;
                    }
                }
            }

            &__list {
                &__item {
                    &__title {
                        font-size: 25px;
                    }

                    &__date {
                        font-size: 18px;
                    }
                }
            }

            &__right {
                padding: 20px 40px;
            }
        }

        &__infos {
            margin-bottom: 150px;

            > .container {
                padding-right: 15px;
                padding-left: 15px;

                > .flex-row {
                    margin-right: -25px;
                    margin-left: -25px;

                    > .flex-col {
                        flex: 0 0 33.3333%;
                        max-width: 33.3333%;
                        padding-right: 25px;
                        padding-left: 25px;
                    }
                }
            }
        }

        &__social {
            margin-bottom: 120px;

            > .container {
                > .flex-row {
                    margin-right: -30px;
                    margin-left: -30px;

                    > .flex-col {
                        flex: 0 0 50%;
                        max-width: 50%;
                        padding-right: 30px;
                        padding-left: 30px;
                    }
                }
            }

            &__heading {
                &__title {
                    font-size: 42px;
                    letter-spacing: 3px;

                    > span span {
                        font-size: 72px;
                    }
                }

                &__subtitle {
                    font-size: 18px;

                    span {
                        font-size: 35px;
                    }
                }
            }

            &__yt-video {
                margin-right: 0;
                margin-left: 0;

                img {
                    max-width: initial;
                }
            }

            &__ig-stories {
                margin-bottom: 0;

                &__list {
                    padding: 40px 30px 15px 40px;
                    margin-right: 0;
                    margin-left: 0;
                }

                &__subtitle {
                    padding: 15px 30px;
                    margin-right: 0;
                    margin-left: 0;
                }
            }

            &__links {
                &__title {
                    font-size: 35px;
                }
            }
        }

        &__numbers {
            padding-top: 35px;
            padding-bottom: 120px;

            &__title {
                margin-bottom: 45px;
                font-size: 45px;

                span {
                    font-size: 90px;
                }
            }

            &__list {
                > .flex-row {
                    > .flex-col {
                        flex: 0 0 20%;
                        max-width: 20%;
                    }
                }

                &__item {
                    > div {
                        font-size: 20px;

                        .number {
                            font-size: 30px;
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 1600px) {
        &__ny-experiences {
            .ny-experiences {
                .ny-experience {
                    .title {
                        font-size: 55px !important;

                        &::after {
                            top: 12%;
                            right: -15%;
                        }
                    }
                }
            }
        }
    }
}
