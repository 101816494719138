@import "common/variables";
@import "common/fonts";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower

@import "common/global";

@import "components/flex-grid";

@import "layouts/header";
@import "layouts/footer";
@import "layouts/front-page";

@import "layouts/offcanvas-header";
@import "layouts/offcanvas-cart";
@import "layouts/offcanvas-book-now";
@import "layouts/footer-2022";
@import "layouts/front-page-2022";
@import "layouts/tour-2022";
@import "layouts/categoria-2022";
@import "layouts/sidebar-2022";
@import "layouts/explore-2022";
@import "layouts/accentheader";
@import "layouts/modal";
@import "layouts/datepicker";
@import "layouts/accomodation";
@import "layouts/kcms";

@import "components/sameheightrows";
@import "components/slick";
@import "components/videojs";
@import "components/overlay-scrollbars";
@import "components/bootstrap-datepicker3";