.offcanvas-header {
    position: fixed;
    left: -100%;
    top: 0;
    z-index: 17000000;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: #fff;
    transition: left 0.3s ease-in-out;

    &._open {
        left: 0;
    }

    &__close {
        position: absolute;
        right: 15px;
        top: 15px;
        z-index: 20;
        width: 40px;
        height: 40px;
        font-size: 0;
        background: url('../images/icon-close2.svg') center center no-repeat;
        background-size: contain;
        border: none;
        opacity: 1;

        &:hover {
            opacity: .75;
        }
    }

    &__navigation {
        overflow: hidden;

        &__title {
            position: relative;
            padding: 20px 30px;
            font-family: 'Raleway', sans-serif;
            font-size: 20px;
            font-weight: 900;
            letter-spacing: 2px;
            text-transform: uppercase;
            color: #9AA7B2;

            &__back {
                position: absolute;
                left: 0;
                top: 50%;
                display: none;
                padding: 20px 50px;
                text-transform: uppercase;
                transform: translateY(-50%);
                background: #fff;
                border: none;

                &::before {
                    position: absolute;
                    left: 30px;
                    top: 50%;
                    background-size: contain;



                    content: "\e259";
                    font-family: Glyphicons Halflings;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    border: none;
                    width: auto;
                    height: auto;
                    vertical-align: initial;
                    font-size: 16px;
                    transform: translateY(-50%) rotate(90deg);
                }

                &._show {
                    display: block;
                }
            }
        }

        &__menu {
            position: relative;
            margin-bottom: 30px;
            border-top: #C7D0D8 1px solid;

            > ul {
                > li {
                    border-bottom: #C7D0D8 1px solid;

                    > a,
                    .title {
                        padding: 20px 30px;
                        font-size: 20px;
                        font-weight: 900;
                    }

                    ul {
                        padding-top: 20px;
                        padding-bottom: 20px;

                        a {
                            padding: 10px 30px;
                            font-size: 16px;
                            font-weight: 600;
                            text-transform: uppercase;
                        }

                        li.title {
                            margin-top: -20px;
                            padding: 0;
                        }
                    }
                }
            }

            ul {
                @include list-unstyled();

                margin-bottom: 0;
                overflow: hidden;

                li {
                    a,
                    .title {
                        display: block;
                        font-family: 'Raleway', sans-serif;
                        text-transform: uppercase;
                        color: #000;
                    }

                    a {
                        &:hover {
                            text-decoration: none;
                            color: $brand-primary;
                        }
                    }

                    &.parent {
                        a {
                            position: relative;
                            padding-right: 60px;

                            .caret {
                                position: absolute;
                                right: 30px;
                                top: 50%;
                                display: inline-block;
                                font-family: Glyphicons Halflings;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 1;
                                -webkit-font-smoothing: antialiased;
                                -moz-osx-font-smoothing: grayscale;
                                border: none;
                                width: auto;
                                height: auto;
                                vertical-align: initial;
                                font-size: 22px;
                                transform: translateY(-50%) rotate(-90deg);
                                color: #C7D0D8;

                                &:before {
                                    content: "\e259";
                                }
                            }
                        }
                    }

                    &.double {
                        &:first-child {
                            padding-bottom: 20px;
                            border-bottom: #C7D0D8 1px solid;

                            .title {
                                margin-top: -20px;
                            }
                        }

                        ul {
                            position: static;
                            padding-top: 0;
                            padding-bottom: 0;
                            border-bottom: none;
                        }
                    }
                }

                ul {
                    position: absolute;
                    left: 100%;
                    top: 0;
                    z-index: 20;
                    width: 100%;
                    background: #fff;
                    border-bottom: #C7D0D8 1px solid;
                    transition: left 0.3s ease-in-out;

                    &._show {
                        left: 0;
                    }
                }
            }
        }
    }

    &__footer {
        padding-right: 30px;
        padding-left: 30px;

        &__top {
            margin-bottom: 30px;
        }
    }

    &__search {
        &__trigger {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 0;
            font-family: 'Raleway', sans-serif;
            font-size: 20px;
            font-weight: 900;
            letter-spacing: 2px;
            text-transform: uppercase;
            background: none;
            border: none;
            color: #000;

            img {
                width: 55px;
                margin-right: 10px;
            }
        }
    }

    &__cart {
        &__trigger {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 0;
            font-family: 'Raleway', sans-serif;
            font-size: 20px;
            font-weight: 900;
            letter-spacing: 2px;
            text-transform: uppercase;
            background: none;
            border: none;
            color: #000;

            img {
                width: 55px;
                margin-right: 10px;
            }
        }
    }

    &__links {
        margin-bottom: 30px;
        font-size: 20px;
        font-weight: 700;
        color: #000;

        a {
            color: #000;

            &:hover {
                text-decoration: none;
                color: #666;
            }
        }

        ul {
            @include list-unstyled();

            margin-bottom: 0;

            li {
                margin-top: 15px;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }

    &__support {
        margin-bottom: 30px;

        &__title {
            margin-bottom: 30px;

            img {
                display: inline-block;
                width: 40px;
            }
        }

        ul {
            @include list-unstyled();

            margin-bottom: 0;

            li {
                margin-top: 15px;

                &:first-child {
                    margin-top: 0;
                }

                font-size: 20px;
                font-weight: 700;
                color: #000;

                a {
                    color: #000;

                    &:hover {
                        text-decoration: none;
                        color: #666;
                    }
                }
            }
        }
    }

    &__social {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        > * {
            flex: 0 0 auto;
            width: auto;
            max-width: 100%;
            margin: 0 10px 10px 0;
        }
    }

    &__languages {
        position: absolute;
        right: 90px;
        top: 25px;

        .dropdown-toggle {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            font-size: 14px;
            font-weight: 700;
            text-decoration: none;
            text-transform: uppercase;
            color: #000;

            img {
                width: 27px;
                margin-right: 10px;
            }
        }

        .dropdown-menu {
            left: auto;
            right: 0;
            padding: 5px 0;
            margin-top: 10px;
            background: $brand-secondary;
            border: none;
            box-shadow: none;
            @include border-top-radius(0);
            @include border-bottom-radius(0);

            &:before{
                content: '';
                position: absolute;
                top: -7px; right: 15px;
                display: block;
                width: 16px; height: 16px;
                margin-left: -8px;
                background: $brand-secondary;
                @include rotate(45deg);
            }
            > li{
                position: relative;
                z-index: 20;

                > a,
                > .title {
                    padding: 5px 15px;
                    color: #fff;

                    &:hover, &:focus{
                        background: none;
                    }
                }
            }
        }
    }

    @media (min-width: $screen-sm-min) {
        right: -500px;
        max-width: 500px;
        border-right: #000 1px solid;
    }

    @media (min-width: $screen-md-min) {
        right: -700px;
        max-width: 700px;
    }

    @media (max-width: $screen-lg-min - 1) {
        &__cart {
            margin-bottom: 15px;
        }

        &__footer {
            &__top,
            &__bottom {
                > .flex-row {
                    > .flex-col {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }
            }
        }
    }

    @media (min-width: $screen-lg-min) {
        &__links {
            margin-bottom: 0;
        }

        &__support {
            margin-bottom: 0;
            text-align: right;
        }

        &__search {
            text-align: right;

            &__trigger {
                display: inline-flex;
            }
        }

        &__footer {
            &__bottom {
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: 0;
                    display: block;
                    width: 1px;
                    height: 100%;
                    background-color: #9AA7B2;
                }
            }
        }

        &__social {
            margin-top: 45px;
        }
    }
}

body.offcanvas-header--shown {
    .wrapper {
        &::after {
            content: "";
            position: fixed;
            left: 0;
            top: 0;
            z-index: 16500000;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.5);
        }
    }
}
