.top-slider{
	> .container-fluid{
		padding: 0;

		.slick{
			.slider-item{
				outline: none;

				> div{
					position: relative;
					height: 400px;
					background: transparent center center no-repeat;
					background-size: cover;

					.caption{
						position: absolute;
						bottom: 0; left: 0;
						width: 100%;
						padding: 0 0 30px 0;

						.title{
							display: block;
							margin: 0 0 15px 0;
							font-family: 'Podkova', serif;
							font-size: em(40);
							line-height: 1;
							color: #fff;
						}
						.btn{
							position: relative;
							padding: 0 30px 0 0;
							margin: 0;
							font-size: em(15);
							font-weight: 900;
							line-height: 1;
							text-transform: uppercase;
							color: #fff;

							&:hover, &:focus{
								text-decoration: none;
							}
							&:hover{
								@include opacity(0.75);
							}
							&:after{
								content: '';
								position: absolute;
								top: 50%; right: 0;
								display: block;
								width: 18px; height: 15px;
								background: url('../images/freccia.png') 0 0 no-repeat;
								background-size: 18px 15px;
								@include transform(0, -50%);
							}
						}
					}
				}
			}
		}
	}
}

.ny-experiences{
	position: relative;
	overflow: hidden;

	&:before{
		content: '';
		position: absolute;
		top: -60px; right: 45%;
		display: block;
		width: 667px; height: 439px;
		background: url('../images/manhattan-map.png') 0 0 no-repeat;
	}
	.search-experience{
		position: relative;
		z-index: 20;
		padding: 30px 0;
		margin-bottom: 65px;
		background: rgba(#ffffff, 0.8);

		.custom-select{
			position: relative;
			overflow: hidden;
			margin-bottom: 30px;
			background: $brand-secondary;
			color: #fff;
			@include border-top-radius(3px);
			@include border-bottom-radius(3px);

			&:before{
				content: '';
				position: absolute;
				top: 5px; right: -50px;
				z-index: 2;
				display: block;
				width: 100px; height: 100px;
				background: $brand-tertiary;
				@include rotate(20deg);
				transition: background 0.3s ease-in-out;
			}
			&:after{
				content: "\e259";
				position: absolute;
				top: 50%; right: 15px;
				z-index: 5;
				display: block;
				font-family: 'Glyphicons Halflings';
				font-size: em(12);
				font-style: normal;
				font-weight: 400;
				line-height: 1;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				@include transform(0, -50%);
			}
			&:hover{
				&:before{
					background: $brand-primary;
				}
			}
			.form-control{
				position: relative;
				z-index: 10;
				height: 58px;
				padding: 15px 60px 15px 25px;
				font-size: em(20);
				font-weight: 900;
				background: none;
				border: none;
				box-shadow: none;
				color: #fff;
				@include border-top-radius(3px);
				@include border-bottom-radius(3px);
				-webkit-appearance: value;
				-moz-appearance:    value;
				appearance:         value;
				cursor: pointer;

				&::-ms-expand {
					display: none;
				}
			}
		}
		.btn{
			height: 58px;
			padding-top: 0;
			padding-bottom: 0;
			font-size: em(20);
			font-weight: 900;
			line-height: 58px;
			text-align: center;
			text-transform: uppercase;
			border: none;
			@include border-top-radius(3px);
			@include border-bottom-radius(3px);
		}
	}
	.ny-experience{
		margin-bottom: 80px;

		> .container{
			> .row{
				> div{
					> .title{
						display: block;
						margin-bottom: 50px;
						font-family: 'Podkova', serif;
						font-size: em(50);
						font-weight: 600;
						line-height: 1;
					}
					> .experiences-cats{
						> .row{
							> div{
								> a{
									position: relative;
									display: block;
									margin-bottom: 30px;
									overflow: hidden;
									background: #000;

									&:hover, &:focus{
										text-decoration: none;
									}
									&:hover{
									}
									&:before{
										content: '';
										position: absolute;
										top: 0; right: -15%;
										z-index: 2;
										display: block;
										width: 50%; height: 150%;
										background: rgba($brand-tertiary, 0.6);
										@include rotate(20deg);
										transition: background 0.3s ease-in-out;
									}
									&:after{
										content: '';
										position: absolute;
										bottom: 30px; right: 30px;
										z-index: 5;
										display: block;
										width: 36px; height: 29px;
										background: url('../images/freccia.png') 0 0 no-repeat;
										background-size: 36px 29px;
									}
									.img-responsive{
										width: 100%;
										max-width: inherit;
										transition: opacity 0.3s ease-in-out;
										@include opacity(0.7);
									}
									.category-title{
										position: absolute;
										top: 25px; left: 0;
										z-index: 20;
										padding: 0 30% 0 20px; 
										font-family: 'Podkova', serif;
										font-size: em(40);
										font-weight: 600;
										line-height: 1;
										color: #fff;
									}
									.category-availability{
										display: none;
										position: absolute;
										bottom: 35px; left: 30px;
										z-index: 20;
										width: 60%;
										padding-left: 35px;
										font-size: em(12);
										font-weight: 900;
										text-transform: uppercase;
										color: #fff;
										@include opacity(0);
										transition: opacity 0.3s ease-in-out;

										&:before{
											content: '';
											position: absolute;
											top: 50%; left: 0;
											display: block;
											width: 25px; height: 31px;
											background: url('../images/marker.png') 0 0 no-repeat;
											background-size: 25px 31px;
											@include transform(0, -50%);
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.recomended-tours{
	margin-bottom: 100px;

	> .container{
		> .row{
			> div{
				> .title{
					display: block;
					margin: 0 0 80px 0;
					font-family: 'Podkova', serif;
					font-size: em(50);
					font-weight: 600;
					line-height: 1;
				}
				> .slick{
					.recomendation-item{
						outline: none;

						.recomendation{
							position: relative;
							display: block;
							overflow: hidden;
							margin-bottom: 30px;

							&:hover, &:focus{
								text-decoration: none;
							}
							.image{
								position: relative;
								overflow: hidden;

								&:before{
									content: '';
									position: absolute;
									top: -45%; right: -15%;
									z-index: 2;
									display: block;
									width: 50%; height: 150%;
									background: $brand-tertiary;
									@include rotate(-35deg);
									transition: background 0.3s ease-in-out;
								}
							}
							> .img-responsive{
								width: 100%;
								max-width: inherit;
							}
							> .caption{
								> .title{
									display: block;
									margin: 15px 0 0 0;
									font-size: em(14);
									font-weight: 900;
									text-transform: uppercase;
									color: #404041;
								}
								> .text{
									display: block;
									font-size: em(12);
									font-weight: 900;
									color: #404041;
								}
							}
							.cta{
								display: none;
							}
							.price{
								position: absolute;
								top: 15px; right: 15px;
								z-index: 10;
								font-size: em(9);
								font-weight: 400;
								line-height: 1;
								text-align: right;
								text-transform: uppercase;
								color: #fff;

								> span{
									display: block;
									font-size: 2.250em;
									font-weight: 900;
								}
							}
							&.big{
								.image{
									&:before{
										top: -91%; right: -21%;
									}
								}
								> .caption{
									position: absolute;
									bottom: 25px;

									> .title, > .text{
										> span{
											display: inline-block;
											padding: 5px;
											background: #fff;
										}
									}
									> .title{
										font-size: em(22);
									}
									> .text{
										font-size: em(14);
									}
								}
							}
							&.special-offer{
								position: relative;
								padding: 30px 45px 30px 15px;
								background: $brand-primary;
								color: #fff;

								&:after{
									content: '';
									position: absolute;
									top: 50%; right: 30px;
									z-index: 5;
									display: block;
									width: 36px; height: 29px;
									background: url('../images/freccia.png') 0 0 no-repeat;
									background-size: 36px 29px;
									@include transform(0, -50%);
								}
								> .caption{
									> .title{
										margin: 0;
										text-transform: none;
										color: #fff;
									}
									> .text{
										color: #fff;
									}
								}
							}
						}
					}
					.slick-arrow{
						position: absolute;
						top: -84px;
						display: block;
						width: 38px; height: 44px;
						margin: 0 30px;
						overflow: hidden;
						outline: none;
						text-indent: -999px;
						background: transparent 0 0 no-repeat;
						background-size: 38px 44px;
						border: none;
						transition: opacity 0.3s ease-in-out;

						&:hover{
							@include opacity(0.75);
						}
						&.slick-prev{
							right: 50%;
							background-image: url('../images/prev.png');
						}
						&.slick-next{
							left: 50%;
							background-image: url('../images/next.png');
						}
					}
				}
			}
		}
	}
}

.quick-links{
	overflow: hidden;

	> .container-fluid{
		padding: 0;

		> .row{
			margin: 0;

			> .row-height{
				> div{
					padding: 0;

					.links{
						> .row{
							margin: 0;

							> .row-height{
								> div{
									position: relative;
									padding: 0 20px 30px 20px;

									&:before{
										content: '';
										position: absolute;
										bottom: 0; left: 0;
										width: 100%; height: 1px;
										background: #E8E9EB;
									}
									.image{
										position: relative;
										margin: 0 -20px 10px -20px;

										.img-responsive{
											width: 100%;
											max-width: inherit;
										}
										.video-play{
											position: absolute;
											top: 50%; left: 50%;
											display: block;
											width: 40px; height: 40px;
											font-size: 18px;
											line-height: 40px;
											text-align: center;
											background: #fff;
											color: $brand-tertiary;
											@include transform(-50%, -50%);
											@include border-top-radius(100%);
											@include border-bottom-radius(100%);
										}
										> a{
											display: block;

											&:hover, &:focus{
												text-decoration: none;
											}
											&:hover{
												@include opacity(0.75);
											}
										}
									}
									.social{
										position: relative;
										margin: 0 -20px 10px -20px;
										background: $brand-secondary;

										> div{
											position: absolute;
											top: 50%; left: 0;
											z-index: 2;
											width: 100%;
											@include transform(0, -50%);
											text-align: center;
											color: #fff;

											> .title{
												display: block;
												margin-bottom: 10px;
												font-size: em(22);
												font-weight: 900;
												text-transform: uppercase;
											}
											a{
												display: inline-block;
												margin: 0 15px;
												font-size: 26px;
												line-height: 1;
												color: #fff;

												&:hover, &:focus{
													text-decoration: none;
												}
												&:hover{
													color: #eee;
												}
											}
										}
										> .img-responsive{
											@include opacity(0);
											width: 100%;
											max-width: inherit;
										}
									}
									> a{
										display: inline-block;
										color: #404041;

										&:hover, &:focus{
											text-decoration: none;
										}
										&:hover{
											@include opacity(0.75);
										}

										.title{
											display: inline-block;
											margin: 0 0 5px 0;
											font-size: em(22);
											font-weight: 900;
										}
									}
									.subtitle{
										display: block;
										font-size: em(14);
										font-weight: 900;
									}
									.btn{
										padding: 0;
										color: $brand-tertiary;

										&:hover, &:focus{
											text-decoration: none;
										}
										&:hover{
											color: $brand-primary;
										}
									}
								}
							}
						}
					}
					.blogs{
						padding: 0 15px;

						> .title{
							display: block;
							margin: 30px 0;
							font-family: 'Podkova', serif;
							font-size: em(80);
							font-weight: 600;
							line-height: 1;
						}
						> .blogs-list{
							> .row{
								margin-bottom: 50px;

								.blog-title{
									display: block;
									margin-bottom: 15px;
									font-size: em(22);
									font-weight: 900;
									color: #404041;

									&:hover, &:focus{
										text-decoration: none;
									}
									&:hover{
										@include opacity(0.75);
									}
								}
								.blog-date{
									display: block;
									margin-bottom: 15px;
									font-size: em(22);
									font-weight: 900;
									color: #888;
								}
								.blog-excerpt{
									margin-bottom: 15px;
									font-size: em(14);
									font-weight: 500;
									color: #888;

									.btn{
										padding: 0;
										color: $brand-tertiary;

										&:hover, &:focus{
											text-decoration: none;
										}
										&:hover{
											color: $brand-primary;
										}
									}
								}
							}
						}
						> .text-right{
							margin-bottom: 30px;
						}
					}
				}
			}
		}
	}
}

@media (min-width: $screen-sm-min) {
	.recomended-tours{
		> .container{
			> .row{
				> div{
					> .title{
						margin-bottom: 50px;
						font-size: em(60);
					}
					> .slick{
						.recomendation-item{
							.recomendation{
								.image{
									&:before{
										right: -32%;
									}
								}
								> .caption{
									position: absolute;
									bottom: 25px;
									padding-right: 20%;

									> .title, > .text{
										> span{
											display: inline-block;
											padding: 3px 5px;
											background: #fff;
										}
									}
									> .title{
										font-size: em(16);
									}
									> .text{
										font-size: em(12);
									}
								}
								&.big{
									.caption{
										bottom: 45px;
									}
									.image{
										&:before{
											right: -28%;
										}
									}
								}
								&.special-offer{
									padding-top: 22px;
									padding-bottom: 22px;

									> .caption{
										position: static;
										right: auto; top: auto;
										padding: 0;

										> .title, > .text{
											> span{
												display: inline;
												padding: 0;
												background: none;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.quick-links{
		> .container-fluid{
			> .row{
				> .row-height{
					> div{
						.links{
							> .row{
								> .row-height{
									position: relative;
								
									&:before{
										content: '';
										position: absolute;
										top: 0; left: 50%;
										width: 1px; height: 100%;
										background: #E8E9EB;
									}
									> div{
										&:before{
											display: none;
										}
									}
								}
							}
						}
						.blogs{
							border-top: #E8E9EB 1px solid;
						}
					}
				}
			}
		}
	}
}

@media (min-width: $screen-md-min) {
	.top-slider{
		> .container-fluid{
			.slick{
				.slider-item{
					> div{
						height: 500px;

						> .img-responsive{
							width: 100%;
							max-width: inherit;
						}
						.caption{
							padding-bottom: 60px;

							.title{
								margin-bottom: 30px;
								font-size: em(60);
							}
						}
					}
				}
			}
		}
	}
	.ny-experiences{
		.search-experience{
			.custom-select{
				margin-bottom: 0;
			}
		}
		.ny-experience{
			> .container{
				> .row{
					> div{
						> .title{
							font-size: em(80);
						}
						> .experiences-cats{
							> .row{
								> div{
									> a{
										.category-title{
											font-size: em(30);
										}
									}
									&.item-1{
										margin-top: -54px;
									}
									&.item-4, &.item-5{
										margin-top: -218px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.recomended-tours{
		> .container{
			> .row{
				> div{
					> .title{
						font-size: em(80);
					}
					> .slick{
						.recomendation-item{
							.recomendation{
								.image{
									&:before{
										right: -17%;
									}
								}
								.price{
									> span{
										font-size: 4.000em;
									}
								}
								&.big{
									.image{
										&:before{
											right: -19%;
										}
									}
								}
								&.special-offer{
									padding-top: 26px;
									padding-bottom: 26px;
								}
							}
						}
					}
				}
			}
		}
	}

	.quick-links{
		border-top: #E8E9EB 1px solid;

		> .container-fluid{
			> .row{
				> .row-height{
					> div{
						.links{
							> .row{
								> .row-height{
									&:after{
										content: '';
										position: absolute;
										top: 0; right: 0%;
										width: 1px; height: 100%;
										background: #E8E9EB;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: $screen-lg-min) {
	.top-slider{
		> .container-fluid{
			.slick{
				.slider-item{
					> div{
						height: 620px;

						.caption{
							padding-bottom: 80px;

							.title{
								font-size: em(80);
							}
							.btn{
								padding-right: 55px;
								font-size: em(30);

								&:after{
									width: 36px; height: 29px;
									background-size: 36px 29px;
								}
							}
						}
					}
				}
			}
		}
	}
	.ny-experiences{
		.ny-experience{
			> .container{
				> .row{
					> div{
						> .title{
							font-size: em(100);
						}
						> .experiences-cats{
							> .row{
								> div{
									> a{
										&:hover{
											&:before{
												background: $brand-primary;
											}
											.img-responsive{
												@include opacity(0.3);
											}
											.category-availability{
												@include opacity(1);
											}
										}
										.category-title{
											top: 30px;
											padding-left: 40px;
											font-size: em(50);
										}
										.category-availability{
											display: block;
											width: 50%;
										}
									}
									&.item-1{
										margin-top: -65px;
									}
									&.item-4, &.item-5{
										margin-top: -272px;
									}
								}
							}
						}
					}
				}
			}
		}
	}	
	.recomended-tours{
		> .container{
			> .row{
				> div{
					> .title{
						font-size: em(100);
					}
					> .slick{
						.recomendation-item{
							.recomendation{
								&:hover{
									.image{
										&:before{
											background: $brand-primary;
										}
									}
								}
								.image{
									&:before{
										right: -28%;
									}
									.img-responsive{
										width: 100%;
										max-width: inherit;
									}
								}
								> .caption{
									> .title{
										font-size: em(18);

										> span{
											padding: 5px 5px 5px 5px;
										}
									}
									> .text{
										font-size: em(12);

										> span{
											padding: 5px 5px 5px 5px;
										}
									}
								}
								&.big{
									.cta{
										position: absolute;
										bottom: 50px; right: 50px;
										display: inline-block;
										padding: 15px 25px;
										font-size: em(14);
										font-weight: 900;
										line-height: 1;
										text-transform: uppercase;
										background: $brand-primary;
										color: #fff;
										@include opacity(0);
										transition: opacity 0.2s ease-in-out;
									}
									&:hover{
										.cta{
											@include opacity(1);
										}
									}
									.image{
										&:before{
											right: -26%;
										}
									}
									> .caption{
										bottom: 50px;

										> .title{
											font-size: em(30);

											> span{
												padding: 10px 10px 5px 10px;
											}
										}
										> .text{
											font-size: em(14);

											> span{
												padding: 5px 10px 10px 10px;
											}
										}
									}
								}
								&.special-offer{
									padding: 28px 30px;
									transition: opacity 0.2s ease-in-out;

									&:hover{
										@include opacity(0.75);
									}
									> .caption{
										> .title{
											font-size: em(30);
										}
										> .text{
											font-size: em(14);
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.quick-links{
		> .container-fluid{
			> .row{
				> .row-height{
					> div{
						.blogs{
							padding: 0 50px;
							border-top: none;
						}
					}
				}
			}
		}
	}
}

@media (min-width: 1500px) {
	/*.ny-experiences{
		.ny-experience{
			> .container{
				> .row{
					> div{
						> .experiences-cats{
							> .row{
								> div{
									> a{
										.category-title{
											top: 50px;
											padding-left: 60px;
										}
									}
									&.item-1{
										margin-top: -76px;
									}
									&.item-4, &.item-5{
										margin-top: -320px;
									}
								}
							}
						}
					}
				}
			}
		}
	}*/
	.recomended-tours{
		> .container{
			> .row{
				> div{
					> .slick{
						.recomendation-item{
							.recomendation{
								.image{
									&:before{
										right: -33%;
									}
								}
								&.big{
									.image{
										&:before{
											right: -28%;
										}
									}
								}
								&.special-offer{
									padding-top: 40px;
									padding-bottom: 40px;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media only screen and (min--moz-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2/1),
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min-device-pixel-ratio: 2) {
	.top-slider>.container-fluid .slick .slider-item>div .caption .btn:after,
	.ny-experiences .ny-experience>.container>.row>div>.experiences-cats>.row>div>a:after,
	.recomended-tours>.container>.row>div>.slick .recomendation-item .recomendation.special-offer:after{
		background-image: url('../images/freccia@2x.png')
	}
	.ny-experiences .ny-experience>.container>.row>div>.experiences-cats>.row>div>a .category-availability:before{
		background-image: url('../images/marker@2x.png');
	}
	.recomended-tours>.container>.row>div>.slick .slick-arrow.slick-prev{
		background-image: url('../images/prev@2x.png');
	}
	.recomended-tours>.container>.row>div>.slick .slick-arrow.slick-next{
		background-image: url('../images/next@2x.png');
	}
}
