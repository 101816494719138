.prefooter {
    &__secure-booking {
        padding-top: 35px;
        padding-bottom: 35px;
        font-family: 'Raleway', sans-serif;
        font-size: 22px;
        font-weight: 900;
        letter-spacing: 2px;
        text-align: center;
        text-transform: uppercase;

        span {
            font-family: 'Brittany', serif;
            font-size: 40px;
            font-weight: 400;
            letter-spacing: initial;
            text-transform: none;
        }
    }

    &__plus {
        padding-top: 30px;
        padding-bottom: 30px;

        &__slider {
            &__item {
                > .flex-row {
                    flex-wrap: initial;
                    align-items: center;

                    .right {
                        flex-basis: 0;
                        flex-grow: 1;
                        max-width: 100%;
                    }

                    .left {
                        flex: 0 0 auto;
                        width: auto;
                        max-width: 100%;
                    }
                }

                &__icon {
                    text-align: center;

                    img {
                        display: inline-block;
                    }
                }

                &__title {
                    font-family: 'Raleway', sans-serif;
                    font-size: 18px;
                    font-weight: 900;
                    text-transform: uppercase;
                }
            }

            .slick-dots {
                @include list-unstyled();

                display: flex;
                justify-content: center;
                margin-top: 20px;

                > li {
                    width: 16px;
                    height: 16px;
                    margin: 0 5px;

                    button {
                        display: block;
                        width: 100%;
                        height: 100%;
                        padding: 0;
                        font-size: 0;
                        background: none;
                        border: #000 1px solid;
                        border-radius: 100%;
                        outline: none;
                    }

                    &.slick-active {
                        button {
                            background: #C4241F;
                        }
                    }
                }
            }
        }
    }

    &__free-cancellation {
        padding-top: 35px;
        padding-bottom: 35px;
        background: $brand-primary;

        > .container-fluid {
            > .flex-row {
                align-items: center;

                .left {
                    flex: 0 0 66.6666%;
                    max-width: 66.6666%;
                }

                .right {
                    flex: 0 0 33.3333%;
                    max-width: 33.3333%;
                }
            }
        }

        &__title {
            display: block;
            margin-bottom: 30px;
            font-family: 'Raleway', sans-serif;
            font-size: 24px;
            font-weight: 900;
            text-transform: uppercase;
        }

        &__subtitle {
            display: block;
            margin-bottom: 15px;
            font-family: 'Raleway', sans-serif;
            font-size: 16px;
            font-weight: 900;
            text-transform: uppercase;
            color: #fff;
        }

        &__image {
            position: relative;
            padding-bottom: 100px;
            text-align: center;

            img {
                display: inline-block;
            }

            span {
                position: absolute;
                left: 50%;
                bottom: 0;
                display: block;
                font-family: 'Brittany', serif;
                font-size: 42px;
                font-weight: 400;
                text-align: center;
                transform: translateX(-50%) rotate(-5deg);
                white-space: nowrap;
            }
        }
    }

    &__testimonials-reviews {
        padding-top: 35px;
        padding-bottom: 60px;
        overflow-x: auto;
        border-top: #000 1px solid;
        border-bottom: #000 1px solid;

        > .container {
            min-width: 400px;

            > .flex-row {
                flex-wrap: initial;
                align-items: center;

                > .right,
                > .left {
                    padding-right: 30px;
                    padding-left: 30px;
                }

                > .right {
                    flex: 0 0 50%;
                    max-width: 75vw;
                }

                > .left {
                    flex: 0 0 auto;
                    width: auto;
                    max-width: 100%;
                }
            }
        }

        &__testimonials {
            .title {
                display: block;
                margin-bottom: 30px;
                font-family: 'Raleway', sans-serif;
                font-size: 30px;
                font-weight: 900;
                letter-spacing: 2px;
                text-align: center;
            }

            .quote {
                margin-bottom: 30px;
                font-size: 16px;
                text-align: center;

                .author {
                    display: block;
                    font-style: italic;
                }
            }

            .rating {
                text-align: center;

                .rating {
                    display: block;
                    margin-bottom: 20px;
                    font-family: 'Spartan', sans-serif;
                    font-size: 45px;
                    font-weight: 900;
                }

                .stars {
                    margin-bottom: 15px;

                    img {
                        display: inline-block;
                    }
                }

                .text {
                    display: block;
                    margin-bottom: 15px;
                    font-size: 18px;
                    font-weight: 900;
                }
            }

            .links {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                a {
                    display: block;
                    flex: 0 0 auto;
                    width: auto;
                    max-width: 100%;
                    margin: 0 5px 5px 5px;
                }
            }
        }

        &__reviews {
            position: relative;

            .title {
                position: absolute;
                left: 0;
                top: 0;
                z-index: 100;
                display: block;
                font-family: 'Brittany', serif;
                font-size: 50px;
                font-weight: 400;
                text-align: center;
                transform: rotate(-5deg);
            }

            &__slider-container {
                padding-right: 60px;
            }

            &__slider {
                &__item {
                    padding-top: 150px;
                    padding-right: 6px;
                    padding-bottom: 6px;

                    > div {
                        position: relative;
                        padding: 90px 25px 30px 25px;
                        background: #fff;
                        border: $brand-secondary 3px solid;
                        box-shadow: 6px 6px 0px 0px $brand-tertiary;

                        .image {
                            position: absolute;
                            right: 30px;
                            top: 0;
                            width: 130px;
                            height: 130px;
                            overflow: hidden;
                            border: #fff 4px solid;
                            border-radius: 100%;
                            transform: translateY(-30%);
                        }

                        .author {
                            display: block;
                            margin-bottom: 20px;
                            font-family: 'Spartan', sans-serif;
                            font-size: 20px;
                            font-weight: 900;
                        }
                    }
                }
                .slick-arrow {
                    position: absolute;
                    top: calc(50% + 75px);
                    width: 63px;
                    height: 63px;
                    font-size: 0;
                    transform: translateY(-50%);
                    background: url('../images/icon-arrow.svg') center center no-repeat;
                    background-size: contain;
                    border: none;
                    outline: none;

                    &.slick-prev {
                        display: none !important;
                        right: calc(100% + 30px);
                        transform: translateY(-50%);
                    }

                    &.slick-next {
                        left: calc(100% + 30px);
                        transform: translateY(-50%) scaleX(-1);
                    }
                }
            }
        }
    }

    &__newsletter {
        padding: 60px 0;
        text-align: center;
        border-top: #E8E9EB 1px solid;

        > .container {
            .title {
                display: block;
                margin-bottom: 15px;
                font-family: 'Raleway', sans-serif;
                font-size: 36px;
                font-weight: 900;
                letter-spacing: 2px;
                line-height: 1;
                text-transform: uppercase;
            }
            p {
                font-size: 18px;
                text-transform: uppercase;
            }
            > .row {
                margin-top: 40px;

                .form-control {
                    height: 60px;
                    padding: 15px 30px;
                    font-size: em(20);
                    font-weight: 400;
                    line-height: 1.428571429;
                    border-color: #000;
                    color: #000;
                    @include border-top-radius(10px);
                    @include border-bottom-radius(10px);

                    &::-webkit-input-placeholder {
                        text-align: center;
                        color: #000;
                    }

                    &:-moz-placeholder {
                        text-align: center;
                        color: #000;
                        opacity:  1;
                    }

                    &::-moz-placeholder {
                        text-align: center;
                        color: #000;
                        opacity:  1;
                    }

                    &:-ms-input-placeholder {
                        text-align: center;
                        color: #000;
                    }

                    &::-ms-input-placeholder {
                        text-align: center;
                        color: #000;
                    }

                    &::placeholder {
                        text-align: center;
                        color: #000;
                    }
                }

                .btn {
                    height: 60px;
                    padding: 15px 30px;
                    font-size: em(20);
                    font-weight: 700;
                    line-height: 1.428571429;
                    text-transform: uppercase;
                    @include border-top-radius(10px);
                    @include border-bottom-radius(10px);
                }
            }
        }

        &--dark {
            background-color: #000;
            color: #fff;
        }
    }

    &__about-us {
        > div {
            position: relative;
            padding: 80px 0;
            background: transparent center center no-repeat;
            background-size: cover;
            color: #fff;

            &:before {
                content: '';
                position: absolute;
                top: 0; left: 0;
                width: 100%; height: 40%;
                z-index: 10;
                background: -moz-linear-gradient(top, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%);
                background: -webkit-linear-gradient(top, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%);
                background: linear-gradient(to bottom, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 );
            }

            &:after {
                content: '';
                position: absolute;
                bottom: 0; left: 0;
                width: 100%; height: 40%;
                z-index: 10;
                background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%);
                background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
                background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
            }

            > .container-fluid {
                position: relative;
                z-index: 20;

                .title {
                    display: block;
                    font-family: 'Brittany', serif;
                    font-size: 80px;
                    font-weight: 400;
                    line-height: 1;
                    text-align: center;
                    color: #fff;
                }

                .text {
                    margin-top: 30px;
                    font-size: 16px;
                    line-height: 1.6;

                    .btn {
                        float: right;
                        margin-top: 30px;
                    }
                }
            }
        }
    }

    &__navigation {
        padding-top: 35px;
        padding-bottom: 35px;

        .title {
            display: block;
            margin-bottom: 15px;
            font-family: 'Raleway', sans-serif;
            font-size: 16px;
            font-weight: 900;
            text-transform: uppercase;
        }

        .subtitle {
            display: block;
            margin-bottom: 30px;
            font-size: 18px;
            font-weight: 900;
        }

        ul {
            @include list-unstyled();

            margin-bottom: 25px;
            font-size: 14px;

            li {
                margin-bottom: 5px;
            }

            a {
                color: #000;
            }
        }

        .social {
            display: flex;
            flex-wrap: wrap;

            a {
                display: block;
                margin: 0 5px 5px 0;
            }
        }

        .offices {
            font-size: 14px;
            line-height: 2;

            a {
                color: #000;
            }
        }
    }

    @media (min-width: $screen-md-min) {
        &__secure-booking {
            padding-top: 70px;
            padding-bottom: 70px;
            font-size: 24px;
            letter-spacing: 10px;

            > .container-fluid {
                padding-right: 60px;
                padding-left: 60px;
            }

            span {
                font-size: 60px;
            }
        }

        &__plus {
            padding-top: 70px;
            padding-bottom: 70px;

            &__slider {
                &__item {
                    padding-left: 30px;
                    padding-right: 30px;
                    border-left: #000 1px solid;

                    &:first-child {
                        border-left-color: transparent;
                    }

                    &__title {
                        text-align: center;
                    }
                }

                .slick-dots {
                    display: none;
                }
            }
        }

        &__free-cancellation {
            padding-top: 90px;
            padding-bottom: 90px;

            &__title {
                font-size: 45px;
            }

            &__image {
                margin-top: -60px;
                margin-bottom: -60px;

                span {
                    left: auto;
                    right: 10%;
                    font-size: 70px;
                    transform: rotate(-10deg);
                }
            }
        }

        &__newsletter {
            padding: 100px 0;

            > .container {
                .title {
                    font-size: 42px;
                    letter-spacing: 6px;
                }
            }
        }

        &__navigation {
            > .container-fluid {
                padding-right: 60px;
                padding-left: 60px;
            }
        }

        &__about-us {
            > div{
                > .container-fluid {
                    .text {
                        margin-top: 100px;
                    }
                }
            }
        }
    }

    @media (min-width: $screen-lg-min) {
        &__testimonials-reviews {
            padding-top: 120px;
            padding-bottom: 120px;

            > .container {
                > .flex-row {
                    > .right {
                        order: 2;
                        padding-left: 70px;
                    }

                    > .left {
                        order: 1;
                        flex: 0 0 50%;
                        max-width: 50%;
                        padding-right: 70px;
                    }
                }
            }

            &__reviews {
                .title {
                    font-size: 60px;
                }
            }

            &__testimonials {
                .title {
                    font-size: 36px;
                }
            }
        }
    }

    @media (min-width: 1600px) {
        &__plus {
            &__slider {
                &__item {
                    padding-left: 60px;
                    padding-right: 60px;
                }
            }
        }
    }
}

.footer-2022 {
    padding-top: 35px;
    padding-bottom: 35px;
    background: #000;
    color: #fff;

    > .container-fluid {
        > .flex-row {
            justify-content: space-between;
        }
    }

    &__navigation {

    }

    &__left,
    &__right,
    &__bottom {
        flex: 0 0 100%;
        max-width: 100%;
    }

    &__bottom {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin-right: -5px;
        margin-left: -5px;

        .legal,
        .copyright {
            width: 100%;
        }

        .legal,
        .copyright,
        .credits,
        .ssl {
            padding-right: 5px;
            padding-left: 5px;
        }

        .credits {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
        }

        .ssl {
            flex: 0 0 auto;
            width: auto;
            max-width: 100%;
        }
    }

    &__members {
        .title {
            display: block;
            margin-bottom: 15px;
            font-family: 'Raleway', sans-serif;
            font-size: 16px;
            font-weight: 900;
            text-align: center;
            text-transform: uppercase;
        }

        .list {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            padding-bottom: 30px;
            margin-right: -15px;
            margin-bottom: 30px;
            margin-left: -15px;

            &::after {
                content: "";
                position: absolute;
                left: 15px;
                top: 100%;
                display: block;
                width: calc(100% - 30px);
                height: 1px;
                background: #fff;
            }

            > div {
                flex: 0 0 25%;
                max-width: 25%;
                padding-right: 15px;
                padding-left: 15px;
                text-align: center;
            }
        }
    }

    &__accepted-payments {
        .title {
            display: block;
            margin-bottom: 15px;
            font-family: 'Raleway', sans-serif;
            font-size: 16px;
            font-weight: 900;
            text-align: center;
            text-transform: uppercase;
        }

        .list {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            margin-right: -5px;
            margin-bottom: 15px;
            margin-left: -5px;

            > div {
                flex-basis: 0;
                flex-grow: 1;
                max-width: 100%;
                padding-right: 5px;
                padding-left: 5px;
                text-align: center;
            }
        }
    }

    &__prices-info {
        margin-bottom: 30px;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
    }

    &__legal {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 15px;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;

        a {
            position: relative;
            flex: 0 0 auto;
            width: auto;
            max-width: 100%;
            display: block;
            padding-left: 13px;
            color: #fff;

            &::before {
                content: "";
                position: absolute;
                left: 6px;
                top: 15%;
                display: block;
                width: 1px;
                height: 70%;
                background: #fff;
            }

            &:first-child {
                padding-left: 0;

                &::before {
                    display: none;
                }
            }
        }
    }

    &__copyright {
        margin-bottom: 30px;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
    }

    &__credits {
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;

        a {
            color: #fff;
        }
    }

    @media (min-width: $screen-md-min) {
        > .container-fluid {
            padding-right: 60px;
            padding-left: 60px;
        }

        &__left,
        &__right {
            flex: 0 0 41.666667%;
            max-width: 41.666667%;
        }

        &__bottom {
            width: 100%;
            margin-top: 30px;

            .legal,
            .copyright {
                flex-basis: 0;
                flex-grow: 1;
                max-width: 100%;
            }

            .legal {
                order: 3;
            }

            .copyright {
                order: 1;
            }

            .credits {
                order: 2;
                flex: 0 0 auto;
                width: auto;
                max-width: 100%;
            }
        }

        &__members {
            .title {
                text-align: left;
            }

            .list {
                margin-bottom: 0;
            }
        }

        &__accepted-payments {
            .title {
                text-align: right;
            }

            .list {
                justify-content: flex-end;
                margin-bottom: 0;

                > div {
                    flex: 0 0 auto;
                    width: auto;
                    max-width: 100%;
                }
            }
        }

        &__prices-info {
            margin-bottom: 15px;
            text-align: right;
        }

        &__legal {
            justify-content: flex-end;
            margin-bottom: 0;
            text-align: right;
        }

        &__copyright {
            margin-bottom: 0;
            text-align: left;
        }

        &__credits {
            text-align: center;
        }

        &__ssl {
            margin-bottom: 15px;
            text-align: right;

            img {
                display: inline-block;
            }
        }
    }
}
