.offcanvas-cart {
    position: fixed;
    right: -100%;
    top: 0;
    z-index: 17000000;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: #fff;
    transition: right 0.3s ease-in-out;

    &._open {
        right: 0;
    }

    &__steps {
        &__step {
            // display: none;
            margin-bottom: 30px;

            /*&._active {
                display: block;
            }*/
        }
    }

    &__close {
        position: absolute;
        right: 15px;
        top: 15px;
        z-index: 20;
        width: 40px;
        height: 40px;
        font-size: 0;
        background: url('../images/icon-close2.svg') center center no-repeat;
        background-size: contain;
        border: none;
        opacity: 1;

        &:hover {
            opacity: .75;
        }
    }

    &__title {
        padding: 20px 30px;
        margin-bottom: 20px;
        font-family: 'Raleway', sans-serif;
        font-size: 20px;
        font-weight: 900;
        letter-spacing: 2px;
        border-bottom: #9AA7B2 1px solid;
        color: #9AA7B2;
    }

    &__go-back {
        padding-top: 20px;
        padding-bottom: 20px;
        margin-top: -40px;
        margin-bottom: 20px;

        &__trigger {
            position: relative;
            padding: 0 20px 0 50px;
            font-family: 'Raleway', sans-serif;
            font-size: 16px;
            font-weight: 900;
            letter-spacing: 2px;
            text-transform: uppercase;
            background: none;
            border: none;
            color: #9AA7B2;

            &::before {
                position: absolute;
                left: 30px;
                top: 50%;
                background-size: contain;



                content: "\e259";
                font-family: Glyphicons Halflings;
                font-style: normal;
                font-weight: 400;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                border: none;
                width: auto;
                height: auto;
                vertical-align: initial;
                font-size: 16px;
                transform: translateY(-50%) rotate(90deg);
            }
        }
    }

    &__cart {
        &__list {
            &__item {
                padding: 0 30px 30px 30px;
                margin-bottom: 30px;
                border-bottom: #9AA7B2 1px solid;

                &__title {
                    margin-bottom: 15px;
                    font-family: 'Raleway', sans-serif;
                    font-size: 20px;
                    font-weight: 900;
                    color: #000;
                }

                &__date {
                    margin-bottom: 15px;
                    font-family: 'Raleway', sans-serif;
                    font-size: 16px;
                    font-weight: 700;
                    color: $brand-secondary;
                }

                &__options {
                    display: flex;
                    flex-wrap: wrap;
                    margin-right: -15px;
                    margin-bottom: 15px;
                    margin-left: -15px;

                    &__item {
                        padding-right: 15px;
                        padding-left: 15px;

                        .type,
                        .qty {
                            display: inline-block;
                            padding-right: 10px;
                            font-size: 16px;
                        }

                        .price {
                            font-family: 'Spartan', sans-serif;
                            font-size: 18px;
                            font-weight: 900;
                            color: $brand-tertiary;
                        }
                    }
                }

                &__controls {
                    margin-bottom: 15px;

                    .btn-link {
                        padding: 0;
                        margin-left: 15px;
                        text-transform: uppercase;
                        color: #000;

                        &:first-child {
                            margin-left: 0;
                        }

                        &.remove {
                            text-transform: none;
                            color: #000;
                        }
                    }
                }

                &__subtotal {
                    font-family: 'Raleway', sans-serif;
                    font-size: 18px;
                    font-weight: 900;
                    text-align: right;
                    color: #000;

                    span {
                        display: inline-block;
                        padding-left: 10px;
                        font-family: 'Spartan', sans-serif;
                    }
                }
            }
        }

        &__totals {
            padding-right: 30px;
            padding-left: 30px;
            text-align: right;
            margin-bottom: 30px;

            .total,
            .total-now {
                font-family: 'Raleway', sans-serif;
                font-weight: 900;

                span {
                    display: inline-block;
                    padding-left: 10px;
                    font-family: 'Spartan', sans-serif;
                }
            }

            .total {
                margin-bottom: 15px;
                font-size: 22px;

                span {
                    font-size: 24px;
                }
            }

            .total-now {
                font-size: 20px;
                font-weight: 700;
                text-transform: uppercase;

                span {
                    font-size: 22px;
                    font-weight: 900;
                    color: $brand-primary;
                }
            }
        }

        &__cta {
            padding-right: 30px;
            padding-left: 30px;
            margin-bottom: 30px;
            text-align: right;

            .btn {
                margin-left: 15px;
                font-weight: 700;
                text-transform: uppercase;

                &:first-child {
                    margin-left: 0;
                }

                &.btn-tertiary {
                    background: #000;
                    color: #fff;
                }
            }
        }

        &__notes {
            padding-right: 30px;
            padding-left: 30px;
            font-family: 'Raleway', sans-serif;
            font-size: 18px;
            font-weight: 700;
            text-align: right;

            .note {
                font-family: 'Montserrat', sans-serif;
                font-size: 16px;
                font-weight: 400;
                color: #9AA7B2;
            }
        }
    }

    &__checkout {
        &__list {
            &__item {
                padding: 0 30px 30px 30px;
                margin-bottom: 30px;
                border-bottom: #9AA7B2 1px solid;

                &__title {
                    margin-bottom: 15px;
                    font-family: 'Raleway', sans-serif;
                    font-size: 20px;
                    font-weight: 900;
                    color: #000;
                }

                &__date {
                    margin-bottom: 15px;
                    font-family: 'Raleway', sans-serif;
                    font-size: 16px;
                    font-weight: 700;
                    color: $brand-secondary;
                }

                &__options {
                    display: flex;
                    flex-wrap: wrap;
                    margin-right: -15px;
                    margin-bottom: 15px;
                    margin-left: -15px;

                    &__item {
                        padding-right: 15px;
                        padding-left: 15px;

                        .type,
                        .qty {
                            display: inline-block;
                            padding-right: 10px;
                            font-size: 16px;
                        }

                        .price {
                            font-family: 'Spartan', sans-serif;
                            font-size: 18px;
                            font-weight: 900;
                            color: $brand-tertiary;
                        }
                    }
                }

                &__notes {
                    margin-bottom: 30px;
                    font-size: 14px;

                    &__label {
                        display: inline-block;
                        padding: 5px 10px;
                        margin-bottom: 15px;
                        font-family: 'Raleway', sans-serif;
                        font-weight: 900;
                        text-transform: uppercase;
                        background: #FFEAF0;
                    }
                }

                &__subtotal {
                    font-family: 'Raleway', sans-serif;
                    font-size: 18px;
                    font-weight: 900;
                    text-align: right;
                    color: #000;

                    span {
                        display: inline-block;
                        padding-left: 10px;
                        font-family: 'Spartan', sans-serif;
                    }
                }
            }
        }

        &__totals {
            padding-right: 30px;
            padding-left: 30px;
            text-align: right;
            margin-bottom: 30px;

            .total,
            .total-now {
                font-family: 'Raleway', sans-serif;
                font-weight: 900;

                span {
                    display: inline-block;
                    padding-left: 10px;
                    font-family: 'Spartan', sans-serif;
                }
            }

            .total {
                margin-bottom: 15px;
                font-size: 22px;

                span {
                    font-size: 24px;
                }
            }

            .total-now {
                font-size: 20px;
                font-weight: 700;
                text-transform: uppercase;

                span {
                    font-size: 22px;
                    font-weight: 900;
                    color: $brand-primary;
                }
            }
        }

        &__notes {
            padding: 30px;
            margin-left: 30px;
            margin-bottom: 30px;
            margin-right: 30px;
            background: #EDEDFF;

            &__label {
                display: inline-block;
                padding: 5px 10px;
                margin-bottom: 5px;
                font-family: 'Raleway', sans-serif;
                font-weight: 900;
                text-transform: uppercase;
                background: #F3E1A3;
            }
        }

        &__form {
            padding-right: 30px;
            padding-left: 30px;

            &__title {
                margin-bottom: 15px;
                font-family: 'Raleway', sans-serif;
                font-size: 22px;
                font-weight: 900;
                text-transform: uppercase;
            }

            &__fieldset {
                .form-control {
                    font-size: 16px;
                    border: 3px #00417C solid;
                }

                .privacy {
                    margin-top: 30px;
                    text-align: center;

                    .form-group {
                        margin-top: 30px;
                        font-weight: 600;
                    }
                }
            }

            &__cta {
                text-align: center;

                .btn {
                    text-transform: uppercase;
                    background: #000;
                    color: #fff;
                }
            }
        }
    }

    &__payment {
        &__booking {
            padding-right: 30px;
            padding-left: 30px;
            margin-bottom: 30px;

            &__details {
                margin-bottom: 30px;
                font-family: 'Raleway', sans-serif;
                font-size: 18px;
                font-weight: 900;
                text-transform: none;

                &__item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-right: -15px;
                    margin-bottom: 15px;
                    margin-left: -15px;

                    > * {
                        padding-right: 15px;
                        padding-left: 15px;
                    }
                }
            }

            &__address {
                max-width: 375px;
                padding: 15px 30px;
                margin-right: auto;
                margin-left: auto;
                font-family: 'Raleway', sans-serif;
                font-size: 18px;
                font-weight: 700;
                text-transform: none;
                background: #E4EAF1;

                &__item {
                    display: flex;
                    align-items: center;
                    margin-right: -15px;
                    margin-bottom: 15px;
                    margin-left: -15px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    > * {
                        padding-right: 15px;
                        padding-left: 15px;

                        &:first-child {
                            flex: 0 0 auto;
                            width: auto;
                            max-width: 100%;
                        }

                        &:last-child {
                            flex-basis: 0;
                            flex-grow: 1;
                            max-width: 100%;
                        }
                    }
                }
            }
        }

        &__list {
            &__item {
                padding: 0 30px 30px 30px;
                margin-bottom: 30px;
                border-bottom: #9AA7B2 1px solid;

                &__title {
                    margin-bottom: 15px;
                    font-family: 'Raleway', sans-serif;
                    font-size: 20px;
                    font-weight: 900;
                }

                &__date {
                    margin-bottom: 15px;
                    font-family: 'Raleway', sans-serif;
                    font-size: 16px;
                    font-weight: 900;
                    color: $brand-secondary;
                }

                &__options {
                    display: flex;
                    flex-wrap: wrap;
                    margin-right: -15px;
                    margin-bottom: 15px;
                    margin-left: -15px;

                    &__item {
                        padding-right: 15px;
                        padding-left: 15px;

                        .type,
                        .qty {
                            display: inline-block;
                            padding-right: 10px;
                            font-size: 16px;
                        }

                        .price {
                            font-family: 'Spartan', sans-serif;
                            font-size: 18px;
                            font-weight: 900;
                            color: $brand-tertiary;
                        }
                    }
                }

                &__notes {
                    margin-bottom: 30px;
                    font-size: 14px;

                    &__label {
                        display: inline-block;
                        padding: 5px 10px;
                        margin-bottom: 15px;
                        font-family: 'Raleway', sans-serif;
                        font-weight: 900;
                        text-transform: uppercase;
                        background: #FFEAF0;
                    }
                }

                &__subtotal {
                    font-family: 'Raleway', sans-serif;
                    font-size: 18px;
                    font-weight: 900;
                    text-align: right;
                    color: #000;

                    span {
                        display: inline-block;
                        padding-left: 10px;
                        font-family: 'Spartan', sans-serif;
                    }
                }
            }
        }

        &__totals {
            padding-right: 30px;
            padding-left: 30px;
            text-align: right;
            margin-bottom: 30px;

            .total,
            .total-now {
                font-family: 'Raleway', sans-serif;
                font-weight: 900;

                span {
                    display: inline-block;
                    padding-left: 10px;
                    font-family: 'Spartan', sans-serif;
                }
            }

            .total {
                margin-bottom: 15px;
                font-size: 22px;

                span {
                    font-size: 24px;
                }
            }

            .total-now {
                font-size: 20px;

                span {
                    font-size: 22px;
                }
            }
        }

        &__payment-method {
            padding-right: 30px;
            padding-left: 30px;
            margin-bottom: 30px;

            &__item {
                display: block;
                padding: 10px;
                font-family: 'Raleway', sans-serif;
                font-size: 18px;
                font-weight: 900;
                text-align: center;
                border: #D9D9D9 3px solid;
                border-radius: 4px;

                input {
                    position: absolute;
                    width: 1px;
                    height: 1px;
                    opacity: 0;

                    &:checked {
                        &+span {
                            color: $brand-secondary;
                        }
                    }
                }
            }
        }

        &__payment-balance {
            padding-right: 30px;
            padding-left: 30px;
            margin-bottom: 30px;

            &__item {
                margin-bottom: 15px;
                font-family: 'Raleway', sans-serif;
                font-size: 18px;
                font-weight: 900;

                span {
                    display: inline-block;
                    padding-left: 10px;
                    font-family: 'Spartan', sans-serif;
                    font-size: 20px;
                }
            }
        }

        &__giftcard {
            padding-right: 30px;
            padding-left: 30px;

            &__title {
                display: flex;
                align-items: center;
                margin-right: -15px;
                margin-bottom: 30px;
                margin-left: -15px;
                font-family: 'Raleway', sans-serif;
                font-weight: 900;
                font-size: 20px;
                color: $brand-secondary;
                cursor: pointer;

                &:last-child {
                    margin-bottom: 0;
                }

                > * {
                    padding-right: 15px;
                    padding-left: 15px;

                    &:first-child {
                        flex: 0 0 auto;
                        width: auto;
                        max-width: 100%;
                    }

                    &:last-child {
                        flex-basis: 0;
                        flex-grow: 1;
                        max-width: 100%;
                    }
                }
            }

            &__content {
                display: none;
                margin-bottom: 30px;

                .form-control {
                    font-size: 16px;
                    border: 3px #00417C solid;
                }

                .btn {
                    text-transform: uppercase;
                    background: $brand-tertiary;
                    color: #fff;
                }
            }
        }

        &__pay {
            padding-right: 30px;
            padding-left: 30px;

            &__title {
                display: flex;
                align-items: center;
                margin-right: -15px;
                margin-bottom: 30px;
                margin-left: -15px;
                font-family: 'Raleway', sans-serif;
                font-weight: 900;
                font-size: 20px;

                &:last-child {
                    margin-bottom: 0;
                }

                > * {
                    padding-right: 15px;
                    padding-left: 15px;

                    &:first-child {
                        flex: 0 0 auto;
                        width: auto;
                        max-width: 100%;
                    }

                    &:last-child {
                        flex-basis: 0;
                        flex-grow: 1;
                        max-width: 100%;
                    }
                }
            }

            &__cta {
                text-align: center;

                .btn {
                    text-transform: uppercase;
                    background: #000;
                    color: #fff;
                }
            }
        }
    }

    &__thankyou {
        &__title {
            padding-right: 30px;
            padding-left: 30px;
            margin-bottom: 60px;
            font-family: 'Raleway', sans-serif;
            font-weight: 900;
            font-size: 20px;
            text-align: center;
            text-transform: uppercase;

            img {
                display: inline-block;
                margin-bottom: 30px;
            }
        }

        &__content {
            padding-right: 30px;
            padding-left: 30px;
            margin-bottom: 60px;
            font-weight: 600;
            text-align: center;
        }

        &__help {
            padding-right: 30px;
            padding-left: 30px;
            text-align: center;

            &__title {
                margin-bottom: 15px;
                font-family: 'Raleway', sans-serif;
                font-weight: 900;
                font-size: 16px;
                text-transform: uppercase;
            }
        }

        &__receipt {
            padding-right: 30px;
            padding-left: 30px;
            margin-top: 60px;
            text-align: center;
        }
    }

    @media (min-width: $screen-sm-min) {
        right: -500px;
        max-width: 500px;
        border-left: #000 1px solid;
    }

    @media (min-width: $screen-md-min) {
        right: -700px;
        max-width: 700px;

        &__cart {
            &__list {
                &__item {
                    position: relative;

                    &__controls {
                        .remove {
                            position: absolute;
                            right: 30px;
                            top: 30px;
                        }
                    }
                }
            }
        }

        &__checkout {
            &__list {
                &__item {
                    position: relative;

                    &__title {
                        padding-right: 150px;
                    }

                    &__subtotal {
                        position: absolute;
                        right: 30px;
                        top: 30px;
                    }
                }
            }
        }

        &__payment {
            &__list {
                &__item {
                    position: relative;

                    &__title {
                        padding-right: 150px;
                    }

                    &__subtotal {
                        position: absolute;
                        right: 30px;
                        top: 30px;
                    }
                }
            }
        }
    }

    @media (min-width: 1600px) {
        &__title {
            padding-top: 20px;
            padding-bottom: 20px;
            margin-bottom: 40px;
            font-size: 24px;
        }

        &__go-back {
            &__trigger {
                font-size: 20px;
            }
        }
    }
}

body.offcanvas-cart--shown {
    .wrapper {
        &::after {
            content: "";
            position: fixed;
            left: 0;
            top: 0;
            z-index: 16500000;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.5);
        }
    }
}
