.kcms {
    &__form {
        padding: 15px;

        &__title {
            display: block;
            margin-bottom: 15px;
            font-family: Raleway, sans-serif;
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 2px;
            text-transform: uppercase;
        }

        .form {
            &-group {
                margin-bottom: 15px;

                textarea {
                    height: 100px;
                }
            }

            &-control {
                font-size: 16px;
                background: transparent;
                border-radius: 0;

                &::placeholder {
                    font-family: Raleway, sans-serif;
                    font-size: 14px;
                    font-weight: 700;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                    opacity: 1;
                }
            }
        }

        &__privacy {
            position: relative;
            padding-left: 30px;
            margin-bottom: 15px;
            font-size: 10px;
            font-style: italic;
            text-transform: uppercase;

            a {
                text-decoration: underline;
                color: currentColor;
            }

            input {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        &__cta {
            .btn {
                width: 100%;
                text-transform: uppercase;
            }
        }

        &--light {
            background-color: #ECECEC;

            .form {
                &-control {
                    border-color: #fff;
                    color: #000;

                    &::placeholder {
                        color: #000;
                    }
                }
            }
        }

        &--dark {
            background-color: #000;
            color: #fff;

            .form {
                &-control {
                    border-color: #676767;
                    color: #fff;

                    &::placeholder {
                        color: #fff;
                    }
                }
            }
        }
    }

    @media (min-width: $screen-sm-min) {
        &__privacy {
            margin-bottom: 0;
        }
    }
}

div.mioindice {
    padding: 15px;

    > a {
        position: relative;
        display: block;
        padding-right: 30px;
        text-decoration: none;

        b {
            float: none;
            font-family: Raleway, sans-serif;
            font-size: 18px;
            font-weight: 900;
            letter-spacing: 2px;
            text-transform: uppercase;
            color: #000;
        }

        img {
            position: absolute;
            right: 0;
            top: 50%;
            width: 22px;
            transform: translateY(-50%);
        }
    }

    ol {
        list-style: none;

        li {
            font-family: Raleway, sans-serif;
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 2px;
            text-transform: uppercase;

            a {
                text-decoration: none;
                color: #000;
            }
        }
    }
}

div.kwidgetexploretotour {
    span {
        font-size: 18px;
    }

    a {
        display: inline-block;
        width: auto;
        padding: 10px 20px;
        font-family: Raleway, sans-serif;
        font-size: 16px;
        font-weight: 900;
        letter-spacing: 2px;
        text-transform: uppercase;
        background-color: $brand-primary;
        transition: background 0.3s ease-in-out;

        span {
            font-size: 16px;
        }

        &:hover {
            background-color: darken($brand-primary, 10%);
        }
    }
}
