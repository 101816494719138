$browser-context: 16; // Default
@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

@mixin transform($x, $y){
	-ms-transform: translate($x, $y);
	-webkit-transform: translate($x, $y);
	transform: translate($x, $y);
}

// Glyphicons font path
$icon-font-path:        "../fonts/";

// Colors
$brand-primary:         #D50F3B;
$brand-secondary:       #06417C;
$brand-tertiary:        #F29FC5;
$brand-quaternary:      #F0F3F5;
