.footer{
	.footer-whyus{
		padding: 80px 0;
		text-align: center;
		border-top: #E8E9EB 1px solid;

		> .container{
			.title{
				display: block;
				margin-bottom: 15px;
				font-family: 'Podkova', serif;
				font-size: em(80);
				font-weight: 600;
				line-height: 1;
			}
			p{
				font-size: em(18);
				font-weight: 700;
				color: #888;
			}
			> .row{
				margin-top: 60px;

				> div{
					> .row{
						> div{
							position: relative;
							padding-top: 80px;
							margin-bottom: 20px;

							.img-responsive{
								position: absolute;
								top: 0; left: 50%;
								display: inline-block;
								@include transform(-50%, 0);
							}
							span{
								display: block;
								font-weight: 600;
								text-transform: uppercase;
							}
						}
					}
				}
			}
		}
	}
	.footer-newsletter{
		padding: 55px 0 100px 0;
		text-align: center;
		border-top: #E8E9EB 1px solid;

		> .container{
			.title{
				display: block;
				margin-bottom: 15px;
				font-family: 'Podkova', serif;
				font-size: em(80);
				font-weight: 600;
				line-height: 1;
			}
			p{
				font-size: em(18);
				font-weight: 700;
				color: #888;
			}
			> .row{
				margin-top: 40px;

				.form-control{
					height: 60px;
					padding: 15px 30px;
					font-size: em(20);
					font-weight: 700;
					line-height: 1.428571429;
					background: $brand-secondary;
					border: $brand-secondary 1px solid;
					color: #fff;
					@include border-top-radius(3px);
					@include border-bottom-radius(3px);

					&::-webkit-input-placeholder {
						color: #fff;
					}
					&:-moz-placeholder{
						color: #fff;
						opacity:  1;
					}
					&::-moz-placeholder {
						color: #fff;
						opacity:  1;
					}
					&:-ms-input-placeholder {
						color: #fff;
					}
					&::-ms-input-placeholder {
						color: #fff;
					}
					&::placeholder{
						color: #fff;
					}
					&:focus{
						background: $brand-tertiary;
						border-color: $brand-tertiary;
					}
				}
				.btn{
					height: 60px;
					padding: 15px 30px;
					font-size: em(20);
					font-weight: 700;
					line-height: 1.428571429;
					text-transform: uppercase;
					@include border-top-radius(3px);
					@include border-bottom-radius(3px);
				}
			}
		}
	}
	.footer-aboutus{
		> div{
			position: relative;
			padding: 80px 0;
			background: transparent center center no-repeat;
			background-size: cover;
			color: #fff;

			&:before{
				content: '';
				position: absolute;
				top: 0; left: 0;
				width: 100%; height: 40%;
				z-index: 10;
				background: -moz-linear-gradient(top, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%);
				background: -webkit-linear-gradient(top, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%);
				background: linear-gradient(to bottom, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 );
			}
			&:after{
				content: '';
				position: absolute;
				bottom: 0; left: 0;
				width: 100%; height: 40%;
				z-index: 10;
				background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%);
				background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
				background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
			}
			> .container{
				position: relative;
				z-index: 20;

				.title{
					display: block;
					font-family: 'Podkova', serif;
					font-size: em(80);
					font-weight: 600;
					line-height: 1;
					color: #fff;
				}
				.text{
					margin-top: 30px;
					font-size: em(20);
					line-height: 1.6;

					.btn{
						float: right;
						margin-top: 30px;
					}
				}
			}
		}
	}
	.footer-nav{
		padding: 60px 0 0 0;

		> .container{
			> .row{
				> .col-xs-12{
					margin-bottom: 60px;

					> .title{
						display: block;
						margin-bottom: 15px;
						font-size: em(18);
						font-weight: 900;
					}
					> ul{
						padding: 0;
						margin: 0;
						list-style: none;

						> li{
							margin: 5px 0;

							> a{
								font-size: em(14);
								font-weight: 500;
								color: #888;

								&:hover, &:focus{
									text-decoration: none;
								}
								&:hover{
									color: #aaa;
								}
							}
						}
					}
					&.office{
						> .title{
							font-size: em(22);
							text-transform: uppercase;
						}
						> div{
							font-size: em(21);

							a{
								color: $brand-tertiary;

								&:hover, &:focus{
									text-decoration: none;
								}
								&:hover{
									color: $brand-secondary;
								}
							}
						}
					}
					&.social{
						> .title{
							font-size: em(22);
							text-transform: uppercase;
						}
						a{
							display: inline-block;
							margin-right: 30px;
							font-size: 26px;
							line-height: 1;
							color: #888;

							&:hover, &:focus{
								text-decoration: none;
							}
							&:hover{
								color: #aaa;
							}
						}
					}
				}
			}
		}
	}
	.footer-bottom{
		padding: 20px 0;
		text-align: center;
		background: #000;
		color: #fff;

		.img-responsive{
			display: inline-block;
		}
		.partners{
			margin-bottom: 15px;
		}
		.tripadvisor{
			margin-bottom: 15px;
		}
		.payment-methods{
			margin-bottom: 15px;

			> span{
				display: block;
				margin-bottom: 5px;
				font-size: em(18);
				font-weight: 900;
			}
			> div{
				> img{
					margin: 0 5px;
				}
			}
		}
		.secure-connection{
			margin-bottom: 15px;
		}
		.legal{
			margin-bottom: 15px;
			font-size: em(12);

			a{
				display: inline-block;
				margin-right: 10px;
				color: #fff;
			}
		}
		.copyright{
			margin-bottom: 15px;
			font-size: em(12);
			font-weight: 700;
		}
		.credits{
			font-size: em(12);

			a{
				display: inline-block;
				margin-right: 10px;
				color: #fff;
			}
		}
	}
}

@media (min-width: $screen-sm-min) {
	.footer{
		.footer-bottom{
			.partners{
				margin-bottom: 0;

				> .row{
					> .row-height{
						> div{
							&:first-child{
								text-align: left;
							}
							&:last-child{
								text-align: right;
							}
						}
					}
				}
			}
			.tripadvisor{
				margin-bottom: 0;
			}
			.payment-methods{
				margin-bottom: 15px;
				text-align: left;

				> div{
					> img{
						margin: 0;
					}
				}
			}
			.secure-connection{
				margin-top: 15px;
				margin-bottom: 0;
			}
			.legal{
				margin-top: 30px;
				margin-bottom: 0;
				text-align: left;
			}
			.copyright{
				margin-top: 30px;
				margin-bottom: 0;
			}
			.credits{
				margin-top: 30px;
				text-align: right;
			}
		}
	}
}

@media (min-width: $screen-md-min) {
	.footer{
		.footer-aboutus{
			> div{
				> .container{
					.text{
						margin-top: 100px;
					}
				}
			}
		}
	}
}
