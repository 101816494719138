.datepicker {
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
    font-weight: 900;
    color: #A8A7A7;

    .datepicker-inline {
        width: 100%;
    }

    .datepicker-days {
        border: #000 1px solid;
    }

    table {
        width:100%;

        tr {
            th {
                &.prev,
                &.next,
                &.datepicker-switch {
                    border-bottom: #000 1px solid;
                }
            }

            td {
                padding: 5px;
                background: none !important;

                strong {
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    font-weight: inherit;
                    line-height: 30px;
                    background: #34A853;
                    color: #fff;
                }

                &:hover {
                    background: none !important;
                }

                &.kactive {
                    strong {
                        background: #34A853;
                        color: #fff;
                    }
                }

                &.disabled {
                    strong {
                        background: #D9D9D9;
                        color: #737373;
                    }
                }

                &.active {
                    strong {
                        background: $brand-secondary;
                        color: #fff;
                    }
                }

                &.old,
                &.new {
                    strong {
                        display: none;
                    }
                }
            }
        }
    }

    @media (min-width: $screen-lg-min) {
        table {
            tr {
                td {
                    padding: 10px;
                }
            }
        }
    }
}
