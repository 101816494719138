.tour-card {
    position: relative;
    border: #000 1px solid;

    &--has-footer {
        padding-bottom: 55px;
    }

    &__link {
        display: block;
        color: #000;

        &:hover,
        &:focus {
            text-decoration: none;
            color: #000;
        }
    }

    &__image {
        position: relative;
        overflow: hidden;

        .img-responsive {
            width: 100%;
        }

        .price {
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    &__price {
        position: relative;
        z-index: 1;
        padding-top: 10px;
        padding-right: 10px;
        font-family: 'Spartan', sans-serif;
        font-size: 16px;
        font-weight: 700;
        text-align: right;
        text-shadow: 2px 2px 5px rgba(0,0,0,0.5);
        color: #fff;

        > span {
            display: block;
            font-size: 24px;
            font-weight: 900;
            background-image: repeating-linear-gradient(to bottom, transparent, transparent 50%, #000 50%, #000);
        }
    }

    &__content {
        padding: 15px;
    }

    &__label-block {
        position: relative;
        z-index: 10;
        display: block;
        margin-top: -15px;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        transform: translateY(-50%);

        span {
            display: inline-block;
            padding: 0 5px;
            background: #000;
            color: #fff;
        }
    }

    &__title {
        display: block;
        margin-bottom: 10px;
        font-family: 'Raleway', sans-serif;
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
    }

    &__description {
        display: block;
        margin-bottom: 20px;
        font-family: 'Raleway', sans-serif;
        font-size: 14px;
        font-weight: 100;
        text-transform: uppercase;
    }

    &__text {
        display: block;
        margin-bottom: 20px;
        font-size: 14px;
    }

    &__duration {
        display: flex;
        align-items: center;
        margin-right: -7.5px;
        margin-bottom: 30px;
        margin-left: -7.5px;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;

        .icon,
        .text {
            max-width: 100%;
            padding-right: 7.5px;
            padding-left: 7.5px;
        }

        .icon {
            flex: 0 0 auto;
            width: auto;
        }

        .text {
            flex-basis: 0;
            flex-grow: 1;
        }
    }

    &__languages {
        display: flex;
        flex-wrap: wrap;

        > * {
            margin: 0 5px 5px 0;
        }

        .icon {
            margin-right: 10px;
        }
    }

    &__card-footer {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-top: #000 1px solid;

        .icon {
            position: absolute;
            left: 15px;
            top: 50%;
            width: 22px;
            transform: translateY(-50%);
        }

        .text {
            padding: 10px 15px 10px 50px;
            font-size: 14px;
            font-weight: 600;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}

.tour-details-2022 {
    &__breadcrumbs {
        margin-bottom: 30px;
        font-family: 'Raleway', sans-serif;
        font-size: 12px;
        font-weight: 600;
    }

    &__title {
        margin-bottom: 30px;
        font-family: 'Raleway', sans-serif;
        font-size: 32px;
        font-weight: 900;
        letter-spacing: 2px;
        text-transform: uppercase;
    }

    &__gallery {
        margin-top: -41px;
        margin-right: -15px;
        margin-bottom: 30px;
        margin-left: -15px;
        overflow: hidden;

        .flex-row {
            margin-right: -7.5px;
            margin-left: -7.5px;

            .flex-col {
                flex: 0 0 100%;
                max-width: 100%;
                padding-right: 7.5px;
                padding-left: 7.5px;

                .slider,
                .cta {
                    height: 100%;
                }
            }
        }

        &__slider {
            &__item {
                height: 100%;

                img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center center;
                }

                > a {
                    position: relative;
                    display: block;
                    height: 100%;

                    &::before {
                        content: "";
                        display: block;
                        padding-top: 40.7978%;
                    }
                }
            }

            .slick-list,
            .slick-track {
                height: 100%;
            }

            .slick-arrow {
                position: absolute;
                top: 50%;
                z-index: 100;
                width: 40px;
                height: 40px;
                font-size: 0;
                background: url('../images/icon-arrow3.svg') center center no-repeat;
                background-size: contain;
                border: none;
                transform: translateY(-50%);

                &.slick-prev {
                    left: 15px;
                    transform: translateY(-50%) scaleX(-1);
                }

                &.slick-next {
                    right: 15px;
                }

                &.slick-disabled {
                    display: none !important;
                }
            }
        }

        &__cta {
            display: flex;
            align-items: center;
            padding: 30px;
            font-family: 'Raleway', sans-serif;
            text-align: center;
            background: #000;
            color: #fff;

            > div {
                width: 100%;
            }

            &__from {
                display: block;
                margin-bottom: 15px;
                font-size: 24px;
                font-weight: 700;

                span {
                    display: block;
                    font-size: 48px;
                    font-weight: 900;
                }
            }

            &__action {
                display: block;
                margin-bottom: 30px;

                .btn {
                    font-size: 24px;
                    text-transform: uppercase;
                }
            }

            &__message {
                display: block;
                font-size: 24px;
                font-weight: 900;

                span {
                    display: block;
                    margin-top: 15px;
                    font-size: 16px;
                    font-weight: 700;
                }
            }
        }

        &__thumbs {
            $self: &;

            margin-top: 15px;
            margin-left: -7.5px;

            &__item {
                padding-right: 7.5px;
                padding-left: 7.5px;
                cursor: pointer;

                img {
                    width: 100%;
                }
            }

            .slick-current {
                opacity: 0.75;
            }

            &:not(.slick-initialized) {
                display: flex;

                #{ $self }__item {
                    flex: 0 0 22.2222%;
                    max-width: 22.2222%;
                }
            }
        }
    }

    &__quick-infos {
        margin-bottom: 45px;

        &__item {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding-bottom: 15px;
            margin-right: -7.5px;
            margin-bottom: 15px;
            margin-left: -7.5px;
            font-family: 'Raleway', sans-serif;
            font-weight: 600;
            border-bottom: #000 1px solid;

            &__icon,
            &__text,
            &__value {
                padding-right: 7.5px;
                padding-left: 7.5px;
            }

            &__icon {
                flex: 0 0 55px;
                max-width: 55px;
            }

            &__text {
                flex-basis: 0;
                flex-grow: 1;
                max-width: 100%;
            }

            &__value {
                flex: 0 0 100%;
                max-width: 100%;
                padding-left: 62.5px;
            }
        }
    }

    &__description {
        margin-bottom: 45px;

        h2 {
            display: block;
            margin-bottom: 30px;
            font-family: 'Raleway', sans-serif;
            font-size: 24px;
            font-weight: 900;
            letter-spacing: 3px;
            text-transform: uppercase;
            color: #000 !important;
        }
    }

    &__highlights {
        margin-bottom: 45px;

        &__title {
            display: block;
            margin-bottom: 30px;
            font-family: 'Raleway', sans-serif;
            font-size: 24px;
            font-weight: 900;
            letter-spacing: 3px;
            text-transform: uppercase;
            color: #000;
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            margin-right: -15px;
            margin-left: -15px;

            &__item {
                position: relative;
                display: flex;
                align-items: center;
                flex: 0 0 100%;
                max-width: 100%;
                padding-right: 15px;
                padding-left: 15px;
                margin-bottom: 30px;
                min-height: 60px;

                &::before {
                    content: '';
                    flex: 0 0 auto;
                    width: auto;
                    max-width: 100%;
                    width: 32px;
                    height: 32px;
                    background: url('../images/icon-check4.svg') center center no-repeat;
                    background-size: contain;
                }

                span {
                    flex-basis: 0;
                    flex-grow: 1;
                    max-width: 100%;
                    padding-left: 15px;
                }
            }
        }
    }

    &__booking {
        $self: &;

        margin-bottom: 45px;

        > .flex-row {
            > .flex-col {
                width: 100%;
            }
        }

        &__title {
            display: block;
            margin-bottom: 30px;
            font-family: 'Raleway', sans-serif;
            font-size: 26px;
            font-weight: 900;
            letter-spacing: 3px;
            text-transform: uppercase;
            color: #000;

            > span {
                background-image: repeating-linear-gradient(to bottom, transparent, transparent 50%, #C0C0C0 50%, #C0C0C0);

                span {
                    font-family: 'Brittany', serif;
                    font-size: 36px;
                    font-weight: 400;
                    letter-spacing: initial;
                    text-transform: none;
                }
            }
        }

        &__datepicker {
            margin-bottom: 30px;
        }

        &__no-results {
            font-family: 'Raleway', sans-serif;
            font-size: 26px;
            font-weight: 900;
            letter-spacing: 3px;
            text-align: center;
            text-transform: uppercase;
            color: #000;

            span {
                font-family: 'Brittany', serif;
                font-size: 36px;
                font-weight: 400;
                letter-spacing: initial;
                text-transform: none;
                color: $brand-primary;
            }
        }

        &__list {
            display: none;

            &__title {
                margin-bottom: 30px;
                font-family: 'Raleway', sans-serif;
                font-size: 24px;
                font-weight: 900;
                letter-spacing: 3px;
                text-align: center;
                text-transform: uppercase;
                color: #0088CC;
            }

            &__items {
                &__item {
                    padding-top: 30px;
                    margin-top: 30px;
                    border-top: #000 1px solid;

                    &:first-child {
                        padding-top: 0;
                        margin-top: 0;
                        border-top: none;
                    }

                    &__title {
                        margin-bottom: 5px;
                        font-family: 'Raleway', sans-serif;
                        font-size: 24px;
                        font-weight: 900;
                        color: #000;
                    }

                    &__price {
                        margin-bottom: 10px;
                        font-family: 'Raleway', sans-serif;
                        font-size: 20px;
                        font-weight: 900;
                        color: $brand-tertiary;
                    }

                    &__description {
                        font-size: 18px;
                    }

                    &__cta {
                        margin-top: 15px;

                        .btn {
                            text-transform: uppercase;
                        }
                    }

                    &__availability {
                        margin-top: 15px;
                        font-family: 'Raleway', sans-serif;
                        font-size: 20px;
                        font-weight: 900;
                        color: #34A853;
                    }
                }
            }
        }

        &._show-list {
            #{ $self }__no-results {
                display: none;
            }

            #{ $self }__list {
                display: block;
            }

        }
    }

    &__accordion {
        margin-bottom: 45px;
        border-top: #37474F 1px solid;

        &__item {
            $self: &;

            border-bottom: #37474F 1px solid;

            &__title {
                position: relative;
                padding: 20px 45px 20px 0;
                font-family: 'Raleway', sans-serif;
                font-size: 20px;
                font-weight: 700;
                text-transform: uppercase;
                cursor: pointer;

                &::after {
                    content: '';
                    position: absolute;
                    right: 30px;
                    top: 50%;
                    width: 30px;
                    height: 30px;
                    background: url('../images/icon-arrow5.svg') center center no-repeat;
                    background-size: contain;
                    transform: translateY(-50%);
                }
            }

            &__content {
                display: none;
                padding: 0 0 30px 0;

                .list-exclusions,
                .list-inclusions {
                    @include list-unstyled();

                    margin-bottom: 0;

                    li {
                        position: relative;
                        padding-left: 30px;
                        margin-bottom: 10px;

                        &::before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 50%;
                            width: 20px;
                            height: 20px;
                            transform: translateY(-50%);
                        }
                    }
                }

                .list-inclusions {
                    li {
                        &::before {
                            background: url('../images/icon-check2.png') center center no-repeat;
                            background-size: contain;
                        }
                    }
                }

                .list-exclusions {
                    li {
                        &::before {
                            background: url('../images/icon-close.svg') center center no-repeat;
                            background-size: contain;
                        }
                    }
                }
            }

            &._show {
                #{ $self }__title {
                    &::after {
                        transform: translateY(-50%) scaleY(-1);
                    }
                }

                #{ $self }__content {
                    display: block;
                }
            }
        }
    }

    &__directions {
        padding-top: 50px;
        padding-bottom: 50px;
        margin-right: -15px;
        margin-bottom: 45px;
        margin-left: -15px;
        background: $brand-quaternary;

        &__heading {
            display: flex;
            margin-bottom: -30px;

            &__title,
            &__subtitle {
                flex-basis: 0;
                flex-grow: 1;
                max-width: 100%;
            }

            &__title {
                font-family: 'Raleway', sans-serif;
                font-size: 22px;
                font-weight: 900;
                letter-spacing: 2px;
                text-align: right;
                text-transform: uppercase;
            }

            &__subtitle {
                padding-left: 15px;
                font-family: 'Brittany', serif;
                font-size: 40px;

                span {
                    display: block;
                    transform: rotate(-5deg);
                }
            }
        }

        &__content {
            padding: 60px 30px 30px 30px;
            background: #fff;
            border: 2px solid silver;
            box-shadow: 4px 4px 0 0 #EA4335;
        }

        &__icons {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            margin-top: 30px;

            > * {
                margin: 0 7.5px 15px 7.5px;
            }
        }

        &__cta {
            margin-top: 45px;
            text-align: center;

            .btn {
                background: transparent;
                border-color: #37474F;
                color: #37474F;
            }
        }

        &__map {
            &__title {
                margin-bottom: 30px;
                font-family: 'Raleway', sans-serif;
                font-size: 26px;
                font-weight: 900;
                letter-spacing: 2px;
                text-align: center;
                color: $brand-secondary;
            }
        }
    }

    &__social {
        padding-bottom: 45px;

        > .container-fluid {
            > .flex-row {
                justify-content: center;

                > .flex-col {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }

        &__heading {
            margin-bottom: 40px;

            > .flex-row {
                align-items: center;

                > .flex-col {
                    &:last-child {
                        flex: 0 0 20%;
                        max-width: 20%;
                    }
                }
            }

            &__title {
                position: relative;
                z-index: 10;
                display: inline-block;
                margin-bottom: 30px;
                font-family: 'Raleway', sans-serif;
                font-size: 24px;
                font-weight: 900;
                letter-spacing: 2px;
                text-transform: uppercase;

                > span {
                    background-image: repeating-linear-gradient(to bottom, transparent, transparent 50%, silver 50%, silver);

                    span {
                        font-family: 'Brittany', serif;
                        font-size: 42px;
                        font-weight: 400;
                        letter-spacing: initial;
                        text-transform: none;
                    }
                }
            }

            &__subtitle {
                display: block;
                font-family: 'Raleway', sans-serif;
                font-size: 12px;
                font-weight: 900;
                text-transform: uppercase;

                span {
                    vertical-align: middle;
                    font-family: 'Spartan', sans-serif;
                    font-size: 18px;
                    font-weight: 900;
                    color: #0052A5;
                }
            }
        }

        &__videos {
            margin-right: -15px;
            margin-left: -15px;
            margin-bottom: 60px;

            .video {
                position: relative;
                width: 75%;
                margin-right: auto;
                margin-left: auto;

                .vjs-theme-nywelcome {
                    overflow: hidden;
                    border-radius: 30px;
                }
            }
        }

        &__links {
            &__title {
                display: block;
                margin-bottom: 45px;
                font-family: 'Raleway', sans-serif;
                font-size: 24px;
                font-weight: 900;
                letter-spacing: 6px;
                line-height: 1;
                text-align: center;
                text-transform: uppercase;
            }

            &__list {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;

                a {
                    flex: 0 0 auto;
                    width: auto;
                    max-width: 100%;
                    margin: 0 15px 15px 15px;
                }
            }
        }

        &__yt-video {
            margin-right: -15px;
            margin-bottom: 45px;
            margin-left: -15px;

            &__title {
                margin-bottom: 45px;
                text-align: center;

                img {
                    display: inline-block;
                }
            }
        }

        &__ig-stories {
            margin-bottom: 45px;

            &__title {
                position: relative;
                z-index: 20;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: -30px;
                font-family: 'Brittany', serif;
                font-size: 60px;
                font-weight: 400;
                line-height: 1;
                text-align: center;

                div {
                    flex: 0 0 auto;
                    width: auto;
                    max-width: 100%;
                    margin-right: 15px;
                }

                span {
                    flex: 0 0 auto;
                    width: auto;
                    max-width: 100%;

                    span {
                        display: block;
                        transform: rotate(-5deg);
                    }
                }
            }

            &__list {
                padding: 40px 15px 15px 15px;
                margin-right: -15px;
                margin-left: -15px;
                background: #C1EFEF;

                &__item {
                    overflow: hidden;
                    border-radius: 10px;

                    img {
                        width: 100%;
                    }
                }
            }

            &__subtitle {
                padding: 15px;
                margin-right: -15px;
                margin-left: -15px;
                font-family: 'Raleway', sans-serif;
                font-size: 20px;
                font-weight: 900;
                text-align: center;
                text-transform: uppercase;
                background: #C1EFEF;

                img {
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }
    }

    &__related {
        margin-bottom: 45px;

        &__title {
            display: block;
            margin-bottom: 30px;
            font-family: 'Raleway', sans-serif;
            font-size: 24px;
            font-weight: 900;
            letter-spacing: 3px;
            text-transform: uppercase;
            color: #000;

            > span {
                background-image: repeating-linear-gradient(to bottom, transparent, transparent 50%, silver 50%, silver);

                span {
                    font-family: 'Brittany', serif;
                    font-size: 36px;
                    font-weight: 400;
                    letter-spacing: initial;
                    text-transform: none;
                }
            }
        }

        &__scroll {
            padding-right: 15px;
            padding-bottom: 1px;
            padding-left: 15px;
            margin-right: -15px;
            margin-left: -15px;
            overflow: auto;
        }

        &__list {
            display: flex;
            margin-right: -15px;
            margin-left: -15px;

            > h2 {
                display: none;
            }

            > div {
                float: none;
                flex: 0 0 75vw;
                max-width: 75vw;
            }

            .tour-card {
                height: 100%;
            }
        }
    }

    &__footer {
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 15px;
        background: #000;
        color: #fff;
        transition: transform .3s ease-in-out;
        transform: translateY(100%);

        &._show {
            transform: translateY(0);
        }

        &__left,
        &__right {
            font-family: 'Raleway', sans-serif;
            font-size: 20px;
            font-weight: 900;

            span {
                font-size: 30px;
            }
        }

        &__center {
            .btn {
                text-transform: uppercase;
            }
        }
    }

    @media (min-width: $screen-md-min) {
        &__breadcrumbs {
            font-size: 14px;
        }

        &__title {
            font-size: 40px;
            letter-spacing: 4px;
        }

        &__gallery {
            margin-top: 0;
            margin-bottom: 60px;

            .flex-row {
                .flex-col {
                    flex: 0 0 33.333333%;
                    max-width: 33.333333%;

                    &:first-child {
                        flex: 0 0 66.666667%;
                        max-width: 66.666667%;
                    }

                    &:last-child {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }
            }
        }

        &__quick-infos {
            max-width: 90%;
            margin-right: auto;
            margin-bottom: 90px;
            margin-left: auto;

            &__item {
                margin-right: 0;
                margin-left: 0;
                font-size: 20px;

                &__icon,
                &__text,
                &__value {
                    padding-right: 15px;
                    padding-left: 15px;
                }

                &__icon {
                    flex: 0 0 70px;
                    max-width: 70px;
                }

                &__value {
                    flex-basis: 0;
                    flex-grow: 1;
                    max-width: 100%;
                    text-align: right;
                }
            }
        }

        &__description {
            max-width: 90%;
            margin-right: auto;
            margin-bottom: 90px;
            margin-left: auto;
            font-size: 18px;
        }

        &__highlights {
            max-width: 90%;
            margin-right: auto;
            margin-bottom: 90px;
            margin-left: auto;

            &__list {
                margin-right: -15px;
                margin-left: -15px;

                &__item {
                    flex: 0 0 50%;
                    max-width: 50%;
                    padding-right: 15px;
                    padding-left: 15px;

                    &::before {
                        width: 60px;
                        height: 60px;
                    }
                }
            }
        }

        &__booking {
            max-width: 90%;
            margin-right: auto;
            margin-bottom: 90px;
            margin-left: auto;

            &._show-list {
                > .flex-row {
                    align-items: initial;
                }
            }

            > .flex-row {
                align-items: center;

                > .flex-col {
                    width: auto;
                }
            }

            &__title {
                font-size: 36px;

                > span {
                    span {
                        font-size: 42px;
                    }
                }
            }

            &__datepicker {
                margin-bottom: 0;
            }

            &__no-results {
                font-size: 28px;

                > span {
                    font-size: 42px;
                }
            }

            &__list {
                &__title {
                    text-align: left;
                }
            }
        }

        &__accordion {
            max-width: 90%;
            margin-right: auto;
            margin-bottom: 90px;
            margin-left: auto;

            &__item {
                &__title {
                    font-size: 24px;
                }
            }
        }

        &__directions {
            position: relative;
            padding-top: 60px;
            padding-bottom: 60px;
            margin-right: -60px;
            margin-bottom: 120px;
            margin-left: -60px;

            &::before {
                content: "";
                position: absolute;
                left: 50%;
                top: 0;
                width: 100vw;
                height: 100%;
                background-color: $brand-quaternary;
                transform: translateX(-50%);
            }

            &__container {
                position: relative;
                width: 60%;
            }

            &__heading {
                margin-bottom: -50px;

                &__title {
                    font-size: 24px;
                }

                &__subtitle {
                    font-size: 55px;
                }
            }

            &__content {
                padding-top: 100px;
            }
        }

        &__social {
            max-width: 90%;
            margin-right: auto;
            margin-bottom: 90px;
            margin-left: auto;

            > .container-fluid {
                padding-right: 0;
                padding-left: 0;

                > .flex-row {
                    margin-right: -30px;
                    margin-left: -30px;

                    > .flex-col {
                        flex: 0 0 50%;
                        max-width: 50%;
                        padding-right: 30px;
                        padding-left: 30px;
                    }
                }
            }

            &__heading {
                &__title {
                    font-size: 26px;
                    letter-spacing: 6px;

                    > span span {
                        font-size: 60px;
                    }
                }

                &__subtitle {
                    font-size: 12px;

                    span {
                        font-size: 24px;
                    }
                }
            }

            &__yt-video {
                margin-right: 0;
                margin-left: 0;
            }

            &__ig-stories {
                margin-bottom: 0;

                &__list {
                    padding: 40px 30px 15px 40px;
                    margin-right: 0;
                    margin-left: 0;
                }

                &__subtitle {
                    padding: 15px 30px;
                    margin-right: 0;
                    margin-left: 0;
                }
            }

            &__videos {
                .video {
                    width: calc(100% - 60px);
                    margin-right: 0;
                }
            }
        }

        &__related {
            max-width: 90%;
            margin-right: auto;
            margin-bottom: 90px;
            margin-left: auto;

            &__title {
                font-size: 36px;
            }

            &__scroll {
                padding-right: 0;
                padding-bottom: 0;
                padding-left: 0;
                margin-right: 0;
                margin-left: 0;
                overflow: initial;
            }

            &__list {
                > div {
                    float: none;
                    flex: 0 0 33.3333%;
                    max-width: 33.3333%;
                }
            }
        }

        &__footer {
            justify-content: center;
            padding: 30px 15px;

            &__left,
            &__right {
                font-size: 24px;

                span {
                    font-size: 36px;
                }
            }

            &__center {
                padding-right: 45px;
                padding-left: 45px;

                .btn {
                    font-size: 24px;
                    text-transform: uppercase;
                }
            }
        }
    }

    @media (min-width: $screen-lg-min) {
        &__booking {
            > .flex-row {
                > .flex-col {
                    width: auto;
                }
            }
        }
    }
}
