.accentheader {
    display: block;
    margin-bottom: 30px;
    font-family: 'Raleway', sans-serif;
    font-weight: 900;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #000 !important;

    &.blue {
        > span {
            background-image: repeating-linear-gradient(to bottom, transparent, transparent 50%, #BADAEF 50%, #BADAEF);
        }
    }

    &.orange {
        > span {
            background-image: repeating-linear-gradient(to bottom, transparent, transparent 50%, #F7AD8D 50%, #F7AD8D);
        }
    }

    &.green {
        > span {
            background-image: repeating-linear-gradient(to bottom, transparent, transparent 50%, #B6C2B8 50%, #B6C2B8);
        }
    }
}